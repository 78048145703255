$c: '.cbdsws-c-link';

#{$c} {
  &__size {
    &--body {
      font-size: $cbds-tkn-typeScale__600 !important;
    }
    &--lead {
      font-size: $cbds-tkn-fontSize__70;
    }
    &--heading {
      // TODO
    }
  }
}
