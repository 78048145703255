// ==========================================================================
// --------------------------------------------------------------------------
// #COLOR-GRID
// --------------------------------------------------------------------------
// ==========================================================================


.cbdsws-c-colorGrid__grid {
  display: flex;
  flex-flow: row wrap;
  margin-top: -$cbds-tkn-space__6x;
  margin-right: -$cbds-tkn-space__4x;
  margin-left: -$cbds-tkn-space__4x;
}


.cbdsws-c-colorGrid__gridItem {
  width: 100%;
  margin-top: $cbds-tkn-space__6x;
  display: flex;
  flex: 0 0 100%;
  flex-direction: row;
  padding-right: $cbds-tkn-space__4x;
  padding-left: $cbds-tkn-space__4x;
}


.cbdsws-c-colorGrid__grid--col3 {
  @include media('>=xs') {
    .cbdsws-c-colorGrid__gridItem {
      flex: 0 0 50%;
    }
  }

  @include media('>=mii') {
    .cbdsws-c-colorGrid__gridItem {
      flex: 0 0 33.333333%;
    }
  }

  @media print {
    .cbdsws-c-colorGrid__gridItem {
      max-width: 275px !important;
    }
  }
}




//
// Tint Stack Variant
// ==========================================================================

.cbdsws-c-colorGrid--tintStack {
  $c: 'cbdsws-c-colorGrid';

  .#{$c}__gridItem {
    margin-top: 0;

    &:first-child {
      margin-top: $cbds-tkn-space__6x;
    }
  }
}





// ==========================================================================
// IE11 Compatibility
// ==========================================================================


@media all and (-ms-high-contrast: none) {
  // All pertain to flexbug #7: https://github.com/philipwalton/flexbugs#flexbug-7

  .cbdsws-c-colorGrid__gridItem {
    flex-basis: calc(100% - #{$cbds-tkn-space__8x});
  }

  .cbdsws-c-colorGrid__grid--col3 {
    @include media('>=xs') {
      .cbdsws-c-colorGrid__gridItem {
        flex-basis: calc(50% - #{$cbds-tkn-space__8x});
      }
    }

    @include media('>=mii') {
      .cbdsws-c-colorGrid__gridItem {
        flex-basis: calc(33.333333% - #{$cbds-tkn-space__8x});
      }
    }
  }
}
