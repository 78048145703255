// ==========================================================================
// --------------------------------------------------------------------------
// #FONT-CARD
// --------------------------------------------------------------------------
// ==========================================================================


.cbdsws-c-fontCard {
  @include cbdsws-card-style;

  &--firaSans {
    font-family: $cbds-tkn-fontFamily__firaSans;
  }

  &--firaMono {
    font-family: $cbds-tkn-fontFamily__firaMono;
  }

  &--citiSans {
    font-family: $cbds-tkn-fontFamily__citiSans;
  }

  &--langoPx {
    font-family: $cbds-tkn-fontFamily__langoPx;
  }

  &--runningHipster {
    font-family: $cbds-tkn-fontFamily__runningHipster;
  }

  &--sweeper {
    font-family: $cbds-tkn-fontFamily__sweeper;
  }

  &--theHand {
    font-family: $cbds-tkn-fontFamily__theHand;
  }

  &--arial {
    font-family: Arial;
  }

  &--trebuchet {
    font-family: 'Trebuchet MS';
  }

  &--bobby {
    font-family: 'Bobby';
  }
}


.cbdsws-c-fontCard__example {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  @include media('>=mii') {
    -ms-flex-wrap: no-wrap;
    flex-wrap: no-wrap;
  }
}




.cbdsws-c-fontCard__textWrap {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;

  @include media('>=mii') {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}




.cbdsws-c-fontCard__text {
  padding: $cbds-tkn-space__4x;
  line-height: 1.4;
  word-break: break-word;
  color: $cbds-tkn-color__neutral--900;
}


.cbdsws-c-fontCard__spec {
  padding: $cbds-tkn-space__3x;
  position: relative;

  @include media('>=md') {
    margin-left: auto;
    padding-top: $cbds-tkn-space__8x;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    text-align: right;
  }

  @include media('>=lg') {
    min-width: 220px;
  }
}


.cbdsws-c-fontCard--with-specs .cbdsws-c-fontCard__spec {
  text-align: left;

  @include media('>=mii') {
    border-left: solid $cbds-tkn-borderWidth__xs $cbds-tkn-color__neutral--80;
  }
}


.cbdsws-c-fontCard__label {
  width: calc(100% - (#{$cbds-tkn-space__2x} * 3));
  top: $cbds-tkn-space__3x;
  right: $cbds-tkn-space__3x;
  font-family: $cbds-tkn-fontRole__ui;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.6px;
  line-height: 1.2;
  text-transform: uppercase;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 1;
  color: $cbds-tkn-color__black;

  @include media('>=mii') {
    position: absolute;
    text-align: right;
  }
}


.cbdsws-c-fontCard__largeSample {
  line-height: 1.2;
  font-size: 130px;
  color: $cbds-tkn-color__black;

  &::before,
  &::after {
    content: "";
    display: block;
    height: 0;
    width: 0;
  }

  &::before {
    margin-bottom: -$cbds-tkn-space__4x;
  }

  &::after {
    margin-top: -$cbds-tkn-space__8x;
  }
}


.cbdsws-c-fontCard__codeSample {
  padding: $cbds-tkn-space__2x $cbds-tkn-space__4x;
  color: $cbds-tkn-color__red;
  font-family: $cbds-tkn-fontRole__code;
  font-size: 12px;
  font-style: normal;
  font-weight: $cbds-tkn-fontWeight__normal;
  line-height: 1.375;
  background-color: $cbds-tkn-color__neutral--40;
  border-top: solid $cbds-tkn-borderWidth__xs $cbds-tkn-color__neutral--80;
  border-radius: 0 0 $cbds-tkn-borderRadius__md $cbds-tkn-borderRadius__md;

  // Make token font-size a little larger in this instance
  .cbdsws-c-inlineCodeBtn--token {
    font-size: .92em;
  }
}


.cbdsws-c-fontCard__textSample {
  margin: $cbds-tkn-space__4x $cbds-tkn-space__4x $cbds-tkn-space__2x;
  padding: $cbds-tkn-space__4x 0 $cbds-tkn-space__2x;
  min-height: 64px;
  border-top: $cbds-tkn-borderWidth__xs dashed $cbds-tkn-color__neutral--100;
}


.cbdsws-c-fontCard__spec-list {
  margin: 0;
  padding: 0;
  font-size: $cbds-tkn-fontSize__20;
  list-style: none;
}


.cbdsws-c-fontCard__spec-list {
  color: $cbds-tkn-color__neutral--700;
}


.cbdsws-c-fontCard__spec-list-item {
  padding: 6px;
  padding-left: 0;
  line-height: 0;
}

  @include media('>=mii') {
  .cbdsws-c-fontCard__spec-list-item {
    padding: 6px;
  }
}


.cbdsws-c-fontCard__spec-label {
  display: inline-block;
  font-weight: 600;
  line-height: 1.2;

  &::before,
  &::after {
    content: "";
    display: block;
    height: 0;
    width: 0;
  }

  &::before {
    margin-bottom: calc(-0.22813em + 0px);
  }

  &::after {
    margin-top: calc(-0.25156em + 0px);
  }
}


.cbdsws-c-fontCard__spec-value {
  display: inline-block;
  font-weight: 400;
  line-height: 1.2;

  &::before,
  &::after {
    content: "";
    display: block;
    height: 0;
    width: 0;
  }

  &::before {
    margin-bottom: calc(-0.22813em + 0px);
  }

  &::after {
    margin-top: calc(-0.25156em + 0px);
  }
}




// ==========================================================================
// Modifiers
// ==========================================================================

.cbdsws-c-fontCard--bobby .cbdsws-c-fontCard__text {
  font-size: $cbds-tkn-fontSize__40;
  line-height: 1.4;
  letter-spacing: 2px;
}

.cbdsws-c-fontCard--firaSans .cbdsws-c-fontCard__text {
  font-size: $cbds-tkn-fontSize__60;
  line-height: 1.4;
  letter-spacing: 4px;
}

.cbdsws-c-fontCard--firaMono .cbdsws-c-fontCard__text {
  font-size: $cbds-tkn-fontSize__60;
  line-height: 1.4;
  letter-spacing: 4px;
}

.cbdsws-c-fontCard--citiSans {
  .cbdsws-c-fontCard__text {
    font-size: $cbds-tkn-fontSize__60;
    line-height: 1.4;
    letter-spacing: 4px;
  }

  .cbdsws-c-fontCard__sampleText {
    font-size: $cbds-tkn-fontSize__80;
    line-height: 1.2;
  }
}


.cbdsws-c-fontCard--langoPx {
  .cbdsws-c-fontCard__text {
    font-size: $cbds-tkn-fontSize__70;
    line-height: 1.4;
    letter-spacing: 4px;
  }

  .cbdsws-c-fontCard__textSample {
    font-size: $cbds-tkn-fontSize__80;
    line-height: 1.2;
  }
}


.cbdsws-c-fontCard--runningHipster {
  .cbdsws-c-fontCard__text {
    font-size: $cbds-tkn-fontSize__80;
    line-height: 1.4;
    letter-spacing: 4px;
  }

  .cbdsws-c-fontCard__textSample {
    font-size: $cbds-tkn-fontSize__100;
    line-height: 1.1;
  }

  // horizontally align right edge better due to slant bleeding into container
  &.cbdsws-c-fontCard--italic .cbdsws-c-fontCard__largeSample {
    padding-right: $cbds-tkn-space__3x;
  }
}


.cbdsws-c-fontCard--sweeper {
  .cbdsws-c-fontCard__text {
    font-size: $cbds-tkn-fontSize__80;
    line-height: 1.4;
    letter-spacing: 4px;
  }

  .cbdsws-c-fontCard__textSample {
    font-size: $cbds-tkn-fontSize__80;
    line-height: 1.2;
  }

  .cbdsws-c-fontCard__sampleText {
    padding-top: $cbds-tkn-space__1x; // vertically center text better
  }

  // horizontally align right edge better due to slant bleeding into container
  &.cbdsws-c-fontCard--italic .cbdsws-c-fontCard__largeSample {
    padding-right: $cbds-tkn-space__2x;
  }
}


.cbdsws-c-fontCard--theHand {
  .cbdsws-c-fontCard__text {
    font-size: $cbds-tkn-fontSize__80;
    line-height: 1.4;
    letter-spacing: 4px;
  }

  .cbdsws-c-fontCard__label {
    font-size: $cbds-tkn-fontSize__20;
  }

  .cbdsws-c-fontCard__textSample {
    font-size: $cbds-tkn-fontSize__90;
    line-height: 1.2;
  }
}


.cbdsws-c-fontCard--trebuchet .cbdsws-c-fontCard__text {
  font-size: $cbds-tkn-fontSize__60;
  line-height: 1.4;
  letter-spacing: 4.6px;
}


.cbdsws-c-fontCard--arial .cbdsws-c-fontCard__text {
  font-size: $cbds-tkn-fontSize__60;
  line-height: 1.4;
  letter-spacing: 2.4px;
}


.cbdsws-c-fontCard--italic {
  .cbdsws-c-fontCard__text,
  .cbdsws-c-fontCard__largeSample,
  .cbdsws-c-fontCard__sampleText {
    font-style: italic;
  }
}
