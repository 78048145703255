
// ==========================================================================
// --------------------------------------------------------------------------
// #COMPONENT CARD GRID
// --------------------------------------------------------------------------
// ==========================================================================


.cbdsws-c-cardGrid__grid {
    display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-row-gap: 24px;
    grid-column-gap: 24px;
    align-items: stretch;
  }


  .cbdsws-c-cardGrid__item {
    display: flex;
  }









  // ==========================================================================
  // Grid Columns
  // ==========================================================================


  .cbdsws-c-cardGrid__grid {
    &--col1 {
      grid-template-columns: 1fr;
    }

    &--col2 {
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    }

    &--col3 {
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }

    &--col4 {
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    }
  }
