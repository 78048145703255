// ==========================================================================
// --------------------------------------------------------------------------
// #CODE
// --------------------------------------------------------------------------
// ==========================================================================

// ==========================================================================
// --------------------------------------------------------------------------
// #CODE
// --------------------------------------------------------------------------
// ==========================================================================

code {

  pre {
      margin: 24px 0 0;
      margin-top: 24px;
      padding: 16px;
      font-family: FiraMono,Consolas,Andale Mono WT,Andale Mono,Lucida Console,Lucida Sans Typewriter,DejaVu Sans Mono,Bitstream Vera Sans Mono,Liberation Mono,Nimbus Mono L,Monaco,Courier New,Courier,monospace;
      font-size: 12px;
      line-height: 16px;
      color: #373535;
      background: #fafafa;
      border: 1px solid #ededed;
      border-radius: 2px;
      overflow: auto;
  }
}


// [dir="ltr"] .cbdsws-l-contentContainer,
// .cbdsws-l-contentContainer {
//   // Target inline code-blocks only <code>...</code>
//   p > code,
//   td > code,
//   li > code {
//     display: inline-block;  // ensure code wraps
//     min-height: 20px;
//     padding: 3px 3px; // 4px -1px to account for border
//     font-size: 12px;
//     line-height: 12px;
//     color: $cbds-tkn-color__orange;
//     background: #fafafa;
//     border: $cbds-tkn-borderWidth__xs solid $cbds-tkn-color__neutral--100;
//     border-radius: $cbds-tkn-borderRadius__sm;
//   }


//   // Target Markdown code-blocks <code><pre>...</pre></code>
//   code pre {
//     margin: $cbds-tkn-space__6x 0 0 0;
//     padding: $cbds-tkn-space__4x;
//     font-family: $cbds-tkn-fontRole__code;
//     font-size: 12px;
//     line-height: 16px;
//     color: $cbds-tkn-color__neutral--900;
//     background: #fafafa;
//     border: $cbds-tkn-borderWidth__xs solid $cbds-tkn-color__neutral--80;
//     border-radius: $cbds-tkn-borderRadius__sm;
//   }


//   button > code {
//     background: none;
//   }
// }




// /// ==========================================================================
// // Highlight JS
// // ==========================================================================

// .hljs-comment {
//   color: $cbds-tkn-textColor__tertiary;
// }
