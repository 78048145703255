// ==========================================================================
// Bootstrap Variables
// ==========================================================================

//
// Variables & Styles sourced from Bootstrap v5.0.0-beta2
// Includes MVP changes to original BS code to align w/ CBDS
//
// TODO: Re-factor variable naming conventions to align with CBDS methodology
//


$cbdsws-tag-font-size:                   12px;
$cbdsws-tag-font-weight:                 $cbds-tkn-fontWeight__semiBold;
$cbdsws-tag-color:                       $cbds-tkn-color__neutral--900;
$cbdsws-tag-padding-y:                   $cbds-tkn-space__1x;
$cbdsws-tag-padding-x:                   $cbds-tkn-space__2x;
$cbdsws-tag-border-radius:               $cbds-tkn-borderRadius__md;
