// Base Layout & Styles


.cbdsws-c-doDont {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.cbdsws-c-doDont__frame {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $cbds-tkn-space__4x;
  border: $cbds-tkn-borderWidth__xs solid $cbds-tkn-color__neutral--80;
  border-radius: $cbds-tkn-borderRadius__md $cbds-tkn-borderRadius__md 0 0;

  // BG Color Modifiers
  &--lightGray {
    background-color: $cbds-tkn-color__neutral--40;
  }

  // Inset Space Modifiers
  &--none {
    padding: 0;
  }
}

.cbdsws-c-doDont__image {
  display: block;
  max-width: 100%;
  height: auto;
}

.cbdsws-c-doDont__body {
  flex: 100%;
  margin: 0;
  background-color: $cbds-tkn-color__neutral--40;
  border: $cbds-tkn-borderWidth__xs solid $cbds-tkn-color__neutral--80;
  border-top-width: 0;
  border-radius: 0 0 $cbds-tkn-borderRadius__md $cbds-tkn-borderRadius__md;

  &:before {
    content: "";
    display: block;
    height: 8px;
    width: 100%;
    border-bottom: 8px solid;
    border-radius: 0;
  }
}

.cbdsws-c-doDont__content {
  padding: 0 $cbds-tkn-space__4x $cbds-tkn-space__4x $cbds-tkn-space__4x;
  font-size: $cbds-tkn-fontSize__20;
  line-height: 1.6;
  color: $cbds-tkn-textColor__secondary;

  > p {
    margin: 0;
  }

  > p:not(:first-child) {
    margin: $cbds-tkn-space__2x 0 0 0;
  }

  ol {
    margin-top: $cbds-tkn-space__2x;
  }
}

.cbdsws-c-doDont__heading {
  font-family: $cbds-tkn-fontRole__ui;
  font-weight: $cbds-tkn-fontWeight__semiBold;
  font-size: $cbds-tkn-fontSize__30;
  line-height: 1.2;
  color: $cbds-tkn-color__black;
  // border-top: ($cbds-tkn-borderWidth__lg * 2) solid $cbds-tkn-color__black;
  padding: $cbds-tkn-space__4x 0 0 0;
}

.cbdsws-c-doDont__bodyText {
  margin-top: $cbds-tkn-space__1x;
}

.cbdsws-c-doDontList {
  margin: 0;
  padding: 0 0 0 $cbds-tkn-space__6x;
}









// Types



// Type: DO


.cbdsws-c-doDont--do {
  .cbdsws-c-doDont__heading {
    color: $cbds-tkn-color__green--700;
  }

  .cbdsws-c-doDont__body:before {
    border-color: $cbds-tkn-color__green--700;
  }
}



// Type: DONT


.cbdsws-c-doDont--dont {
  .cbdsws-c-doDont__heading {
    color: $cbds-tkn-color__red--700;
  }

  .cbdsws-c-doDont__body:before {
    border-color: $cbds-tkn-color__red--700;
  }
}









// Variants



// No Image Variant


.cbdsws-c-doDont--noImg {
  .cbdsws-c-doDont__body {
    margin-top: 0;

    // Round top corners of bar when there is no image
    &:before {
      border-top-left-radius: $cbds-tkn-borderRadius__md;
      border-top-right-radius: $cbds-tkn-borderRadius__md;
    }
  }
}









// IE11 Compatibility


@media all and (-ms-high-contrast: none) {
  .cbdsws-c-doDont {
    height: 100%;
  }

  .cbdsws-c-doDont__body {
    flex: 1 1 auto;
  }
}
