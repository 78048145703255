// ==========================================================================
// --------------------------------------------------------------------------
// #COLOR
// --------------------------------------------------------------------------
// ==========================================================================


.cbdsws-u-color__green {
  color: $cbds-tkn-color__green;
}
