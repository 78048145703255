// ==========================================================================
// --------------------------------------------------------------------------
// #SIZE
// --------------------------------------------------------------------------
// ==========================================================================


.cbdsws-u-size__maxW--480 {
  max-width: 480px;
}


.cbdsws-u-size__maxW--640 {
  max-width: 640px;
}
