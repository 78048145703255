/*! cbds-site @version */

// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS WEBSITE
// --------------------------------------------------------------------------
// ==========================================================================


//
// cbds-site, by @ryanbelisle
//
// Repo -> TODO: Add repo url
// Site -> TODO: Add site url
//

//
// The CSS output of this file is loaded into the Fractal Mandelbrot theme.
// This allows Mandelbrot to use our theme overrides in <cbdsws-theme>.
// All <cbdsws> styles are also loaded which allows the <cbdsws> components
// and styles to be used on Documentation pages since Documentation pages
// don't have VIEW TEMPLATES like component pages do.
//




//
// CONTENTS
// --------------------------------------------------------------------------
//
// PROJECTS
// Cbdsws-theme.........Fractal theme specific overrides and components.
// Cbdsws...............CBDSWS specific styles & components.
//




//
// PROJECTS
// ==========================================================================

@import 'cbdsws-theme/cbdsws-theme';
@import 'cbdsws/cbdsws';



//
// CBDS GRID
// ==========================================================================

@import 'cbds-layout-grid';


//
// Fancybox lightbox
// ==========================================================================

// @import './fancybox';

//
// PAGES
// ==========================================================================

@import './pages/all';


//
// GLOBAL STYLES FROM CBDS COMPONENTS
// ==========================================================================
@import '@cbds/cbds-components/src/scss/cbds-components-global';
@import '@cbds/cbds-components/src/scss/cbds-experimental';

//
// Allows styled cbds-components in Docs pages.
// Scoping to the <codeDuo> component for now so the styles don't leak out
// and effect the Fractal UI
//

[dir=ltr] .cbdsws-c-codeDuo .cbdsws-c-codeDuo__previewExample,
.cbdsws-c-codeDuo .cbdsws-c-codeDuo__previewExample {
  // @import '@cbds/cbds-components/src/scss/cbds-components-global';
  // @import '@cbds/cbds-components/src/scss/cbds-experimental';
}

#skipLink {
  z-index: 3;
  top: 0;
}

.cbdsws-l-mainContainer {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0;
}

.cbdsws-l-sidebarContainer {
  flex: 0 0 336px;
  height: 100%;
  transition: all 0.25s ease;
  overflow-y: scroll; // stops the nav icons from jumping left when scrollbar appears
  background: $cbds-tkn-color__white;
}

.cbdsws-l-contentContainer {
  flex: 1;
  padding: 32px 64px !important;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (max-width: $cbds-tkn-breakpoint__sm) {
    padding: 32px 32px !important;
  }

  main {
    width: 100%;
  }
}

.code.language-html {
  white-space: pre-wrap;
}

.cbds-sandbox-block {
  display: flex;
  margin-top: 10px;

  &img {
    position: relative;
    top: 8px;
    margin-right: 10px;
  }
}

video#layoutGridPart1 {
  width: 100%;
  height: auto;
}

.cbdsws-c-codeDuo .cbdsws-c-codeDuo--icon {
  margin-top: 10px;
}

  h2.cbds-c-accordion__heading {
    margin-bottom: 0;
  }

.copy .cbdsws-c-markdown__h2 {
  font-family: Bobby, serif !important;
  font-weight: $cbds-tkn-fontWeight__300;
}

.copy sub {
  font-size: $cbds-tkn-fontSize__30;
}

.cbds-c-colorCard-grid {
  display: inline-flex;
  flex-direction: row;
  gap: 10px;

  @media (max-width: 1280px) {
    display: flex;
    flex-direction: column;
  }
}
