// ==========================================================================
// --------------------------------------------------------------------------
// #TABLES
// --------------------------------------------------------------------------
// ==========================================================================

table {
  width: 100%;
  font-feature-settings: "lnum" 1, "tnum" 1;
  font-size: 14px;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 24px 0 0;

  tr {
    border-bottom: 1px solid #ededed;
  }

  th,
  td {
    vertical-align: top;
    padding-left: 8px;
  }

  th {
    text-align: left;
    font-family: FiraSans,Arial,Helvetica Neue,Helvetica,sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2;
    background-color: #E9E9E9;
    border-bottom: 2px solid #ededed;
    padding: 12px 8px 10px;
  }

  td {

    > code {
      display: inline-block;
      min-height: 20px;
      padding: 3px;
      font-size: 12px;
      line-height: 12px;
      background: #fafafa;
      border: 1px solid #e9e9e9;
      border-radius: 2px;
      margin-right: 8px;
    }
  }
}

.cbdsws-u-table__responsiveWrapper {
  display: block;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}


caption {
  font-weight: 700;
  text-align: left;
  font-size: 20px;
  margin-bottom: 12px;
}

@media screen and (max-width: $cbds-tkn-breakpoint__lg) {
  th {
    display: none;
  }
  td {
    display: block;
    white-space: pre-wrap;

    &::before {
      padding: 0 !important;
    }

  }
  td::before {
    content: attr(data-cell) ": ";
    font-weight: 700;
    padding: 0 8px;
  }
  tr:nth-of-type(2n) {
    background: #E9E9E9;
    padding: 4px;
  }
}
