// ==========================================================================
// --------------------------------------------------------------------------
// #CBDSWS Tag
// --------------------------------------------------------------------------
// ==========================================================================

@import './Tag__tokens';








// ==========================================================================
// Base Layout & Styles
// ==========================================================================


.cbdsws-c-tag {
  @include cbds-box-sizing;

  display: inline-flex;
  align-items: center;
  min-width: 24px;
  height: 24px;
  margin: 0;
  // Padding setup to get 24px height
  padding: $cbdsws-tag-padding-y $cbdsws-tag-padding-x;
  font-family: $cbds-tkn-fontRole__ui;
  font-style: $cbds-tkn-fontStyle__normal;
  font-size: $cbdsws-tag-font-size;
  font-weight: $cbdsws-tag-font-weight;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;
  color: $cbdsws-tag-color;
  background-color: $cbds-tkn-color__neutral--60;
  border-radius: $cbdsws-tag-border-radius;
}








// ==========================================================================
// Variants (Color)
// ==========================================================================

.cbdsws-c-tag {
  &--primary {
    color: $cbds-tkn-color__white;
    background-color: $cbds-tkn-color__green--600;
  }

  &--secondary {
    color: $cbds-tkn-color__white;
    background-color: $cbds-tkn-color__neutral--700;
  }

  // Semantic colors - Match CBDS Button colors
  &--success {
    color: $cbds-tkn-color__white;
    background-color: $cbds-tkn-color__green--700;
  }

  &--error {
    color: $cbds-tkn-color__white;
    background-color: $cbds-tkn-color__red--700;
  }

  &--warning {
    color: $cbds-tkn-color__white;
    background-color: $cbds-tkn-color__gold--800;
  }

  &--info {
    color: $cbds-tkn-color__white;
    background-color: $cbds-tkn-color__fadedBlue--700;
  }

  // Other theme colors (from Bootstrap)
  &--light {
    color: $cbds-tkn-color__neutral--900;
    background-color: $cbds-tkn-color__neutral--60;
  }

  &--dark {
    color: $cbds-tkn-color__white;
    background-color: $cbds-tkn-color__neutral--900;
  }


  // Software Release
  &--compExp {
    color: $cbds-tkn-color__neutral--700;
    background: $cbds-tkn-color__neutral--60;
  }

  &--compPreAlpha,
  &--compAlpha {
    color: $cbds-tkn-color__gold--800;
    background: $cbds-tkn-color__gold--100;
  }

  &--compBeta {
    color: $cbds-tkn-color__fadedBlue--800;
    background: $cbds-tkn-color__fadedBlue--100;
  }

  &--compRC,
  &--compVariantNew {
    color: $cbds-tkn-color__green--800;
    background: $cbds-tkn-color__green--100;
  }

  &--compNew {
    color: $cbds-tkn-color__white;
    background: $cbds-tkn-color__green--700;
  }

  &--compDep,
  &--compVariantDep,
  &--compVariantBrk {
    color: $cbds-tkn-color__red--800;
    background: $cbds-tkn-color__red--100;
  }
}





// ==========================================================================
// Variants (Style)
// ==========================================================================

.cbdsws-c-tag--pill {
  border-radius: 9999px;
}








// ==========================================================================
// Variants (Size)
// ==========================================================================

.cbdsws-c-tag--sm {
  height: 18px;
  padding: 2px 4px;
  font-size: 11px;
  line-height: 13px;
}








// ==========================================================================
// Variants (Font Weight)
// ==========================================================================

.cbdsws-c-tag--fwNormal {
  font-weight: $cbds-tkn-fontWeight__normal;
}
