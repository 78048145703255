// ==========================================================================
// --------------------------------------------------------------------------
// #BASE
// --------------------------------------------------------------------------
// ==========================================================================


html {
  color: $cbds-tkn-body__color;
  font: calc($cbds-tkn-body__fontSize/1.5) $cbds-tkn-body__fontFamily;
  -webkit-font-smoothing: auto; // Turning to auto. Renders FiraSans a bit heavier
}

body {
  font-size: $cbds-tkn-typeScale__600;
  font-family: $cbds-tkn-body__fontFamily;
  line-height: $cbds-tkn-unit__lineHeight * 8;
}

a {
  color: $cbds-tkn-color__blue--700;
  font-weight: inherit;

  &:visited,
  &:focus,
	&:hover,
	&:active {
		text-decoration: underline;
  }

  &:visited {
    color: $cbds-tkn-color__neutral--600;
  }

  &:focus {
    @include cbds-focus-outline;
    color: $cbds-tkn-color__blue--700;
    text-decoration: underline;
    background: transparent;
    border: none;
  }

	&:hover {
		color: $cbds-tkn-color__blue--700;
  }

	&:active {
		color: $cbds-tkn-color__blue--900;
  }

  &[disabled],
  &[aria-disabled="true"] {
		color: $cbds-tkn-color__neutral--600;
		text-decoration: none;
		cursor: not-allowed;
	}
}

dt {
  font-weight: bold;
}

dt:not(:first-child) {
  margin-top: $cbds-tkn-space__4x;
}




// ==========================================================================
// Set Fonts Everywhere
// ==========================================================================

html,
h1,
h2,
h3,
h4,
h5,
h6,
.Tree-title,
.Tree-collectionLabel,
.Tree-item,
.Document-title,
.Pen-title,
.Status-label,
.cbdsws-l-contentContainer,
.cbdsws-l-contentContainer h1,
.cbdsws-l-contentContainer h2,
.cbdsws-l-contentContainer h3,
.Browser-tab a,
.Meta-key,
.Meta-value {
  font-family: $cbds-tkn-body__fontFamily;
}
