// ==========================================================================
// --------------------------------------------------------------------------
// #LEAD
// --------------------------------------------------------------------------
// ==========================================================================


.cbdsws-c-lead {
  font-family: $cbds-tkn-fontRole__ui;
  font-weight: $cbds-tkn-fontWeight__light;
  font-size: $cbds-tkn-fontSize__70;
  line-height: 1.6;
  letter-spacing: -0.5px;
  color: $cbds-tkn-color__black;

  @media print {
    font-size: $cbds-tkn-fontSize__50;
  }
}


// Lead for Release Note page
.cbdsws-c-lead--sm {
  font-size: $cbds-tkn-fontSize__50;
  line-height: 1.5;
}
