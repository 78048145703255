// ==========================================================================
// --------------------------------------------------------------------------
// #TOKENS TABLE
// --------------------------------------------------------------------------
// ==========================================================================


.cbdsws-c-tokensTable {
  th {
    padding: $cbds-tkn-space__3x $cbds-tkn-space__3x 10px $cbds-tkn-space__3x;
  }

  td {
    padding: $cbds-tkn-space__6x $cbds-tkn-space__3x;

    @media screen and (max-width: $cbds-tkn-breakpoint__md) {
      display: flex;
      gap: 15px;
      padding: $cbds-tkn-space__2x $cbds-tkn-space__3x;
    }

  }
}


.cbdsws-c-tokensTable__statusHead {
  width: 80px;
  min-width: 80px;
  text-align: center;
}

.cbdsws-c-tokensRow__fontFamily,
.cbdsws-c-tokensRow__fontRole {
  td:first-child {
    width: auto;
    min-width: unset;
  }
}




// ==========================================================================
// Type Modifiers
// ==========================================================================

.cbdsws-c-tokensTable--space {

  .cbdsws-c-tokensTable__value {
    width: 108px;
    min-width: 108px;
  }

  @media print {
    table-layout: auto;
  }
}

caption.cbdsws-c-tokensTable-caption {
  text-align: left;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: $cbds-tkn-breakpoint__md) {
  .cbdsws-c-tokensTable__blank {
    display: none;

    &::before {
      display: none;
    }
  }
}
