// ==========================================================================
// --------------------------------------------------------------------------
// #ICON-GRID
// --------------------------------------------------------------------------
// ==========================================================================


.cbdsws-c-iconGrid {
  margin-top: $cbds-tkn-space__12x;
}


.cbdsws-c-iconGrid__grid {
  display: flex;
  flex-flow: row wrap;
  margin-top: -$cbds-tkn-space__8x;
  margin-right: -$cbds-tkn-space__4x;
  margin-left: -$cbds-tkn-space__4x;

  @media screen and (max-width: $cbds-tkn-breakpoint__sm) {
    justify-content: center;
  }
}


.cbdsws-c-iconGrid__gridItem {
  width: 100%;
  margin-top: $cbds-tkn-space__8x;
  display: flex;
  flex: 0 0 100%;
  flex-direction: row;
  flex-wrap: wrap;
  // Fill entire width for now using $cbds-tkn-space__8x gutter vs $cbds-tkn-space__12x
  width: 200px;
  padding-right: $cbds-tkn-space__4x;
  padding-left: $cbds-tkn-space__4x;
}


.cbdsws-c-iconGrid__grid--col4 {
  @include media('>=xs') {
    .cbdsws-c-iconGrid__gridItem {
      flex: 0 0 50%;
    }
  }

  @include media('>=768px') {
    .cbdsws-c-iconGrid__gridItem {
      flex: 0 0 33.333333%;
    }
  }

  @include media('>=lg') {
    .cbdsws-c-iconGrid__gridItem {
      flex: 0 0 25%;
    }
  }
}






// ==========================================================================
// IE11 Compatibility
// ==========================================================================


@media all and (-ms-high-contrast: none) {
  // All pertain to flexbug #7: https://github.com/philipwalton/flexbugs#flexbug-7

  .cbdsws-c-iconGrid__gridItem {
    flex-basis: calc(100% - #{$cbds-tkn-space__8x});
  }

  .cbdsws-c-iconGrid__grid--col4 {
    @include media('>=xs') {
      .cbdsws-c-iconGrid__gridItem {
        flex-basis: calc(50% - #{$cbds-tkn-space__8x});
      }
    }

    @include media('>=768px') {
      .cbdsws-c-iconGrid__gridItem {
        flex-basis: calc(33.333333% - #{$cbds-tkn-space__8x});
      }
    }

    @include media('>=lg') {
      .cbdsws-c-iconGrid__gridItem {
        flex-basis: calc(25% - #{$cbds-tkn-space__8x});
      }
    }
  }
}
