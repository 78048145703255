// ==========================================================================
// --------------------------------------------------------------------------
// #ACTION CARD
// --------------------------------------------------------------------------
// ==========================================================================


// ==========================================================================
// Base Layout & Styles
// ==========================================================================


// Hack div container to fix NJK/MD rendering when using <a> container
.cbdsws-c-cardContainerFix {
  display: flex;
  width: 100%;
  height: 100%;
}


.cbdsws-c-card {
  position: relative;
  display: block;
  // display: flex;
  // flex-direction: column;
  width: 100%;
  min-width: 0;
  text-decoration: none;
  word-wrap: break-word;
  color: $cbds-tkn-color__neutral--700;
  @include cbdsws-card-style(false);


  &--isAction {
    @include cbdsws-card-style;
    border-color: $cbds-tkn-color__neutral--200;

    &,
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }

    &:visited {
      color: $cbds-tkn-color__neutral--700;
    }

    &:focus {
      border-color: $cbds-tkn-color__neutral--400;

      .cbdsws-c-card__heading {
        color: $cbds-tkn-color__neutral--900;
        text-decoration: underline;
      }
    }

    &:hover {
      border-color: $cbds-tkn-color__neutral--400;

      .cbdsws-c-card__heading {
        color: $cbds-tkn-color__black;
        text-decoration: underline;
      }
    }

    &:active {
      border-color: $cbds-tkn-color__neutral--500;

      .cbdsws-c-card__heading {
        color: $cbds-tkn-color__black;
        text-decoration: underline;
      }
    }
  }

  &--isDisabled {
    background-color: $cbds-tkn-color__neutral--40;

    .cbdsws-c-card__heading {
      font-weight: $cbds-tkn-fontWeight__normal;
      color: $cbds-tkn-color__neutral--700;
    }
  }
}


.cbdsws-c-card__bar {
  position: absolute;
  height: 4px;
  width: 100%;
  background-color: transparent;
  // background-color: $cbds-tkn-color__green;
  border-radius: $cbds-tkn-borderRadius__md $cbds-tkn-borderRadius__md 0 0;
}


.cbdsws-c-card__inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}


.cbdsws-c-card__content {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: $cbds-tkn-space__6x $cbds-tkn-space__4x $cbds-tkn-space__6x $cbds-tkn-space__4x;
}


.cbdsws-c-card__title {
  // margin: 0 0 $cbds-tkn-space__3x 0;

  + .cbdsws-c-card__body {
    margin-top: $cbds-tkn-space__3x;
  }
}


.cbdsws-c-card__body {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  // display: flex;
  // flex: 1 1 auto;
  // flex-direction: column;
  // padding: $cbds-tkn-space__6x $cbds-tkn-space__4x $cbds-tkn-space__4x $cbds-tkn-space__4x;
  // margin: $cbds-tkn-space__3x 0 0 0;
}


.cbdsws-c-card__eyebrow {
  display: block;
  margin: 0 0 8px 0;
  font-family: $cbds-tkn-fontRole__ui;
  font-weight: $cbds-tkn-fontWeight__light;
  font-size: $cbds-tkn-fontSize__10;
  line-height: 1.2;
  color: $cbds-tkn-textColor__quat;
  text-transform: uppercase;
  letter-spacing: .4px;
}


.cbdsws-c-card__heading {
  margin: -4px 0 0 0;
  font-family: $cbds-tkn-fontRole__ui;
  font-weight: $cbds-tkn-fontWeight__semiBold;
  font-size: $cbds-tkn-fontSize__40;
  line-height: 1.3;
  color: $cbds-tkn-color__black;
}


.cbdsws-c-card__subHeading {
  margin: 4px 0 0 0;
  font-family: $cbds-tkn-fontRole__ui;
  font-weight: $cbds-tkn-fontWeight__normal;
  font-size: $cbds-tkn-fontSize__20;
  line-height: 1.2;
  color: $cbds-tkn-color__neutral--900;
}


.cbdsws-c-card__meta {
  margin: $cbds-tkn-space__1x 0 0 0;
  padding: 0;
  font-family: $cbds-tkn-fontRole__ui;
  font-weight: $cbds-tkn-fontWeight__light;
  font-size: $cbds-tkn-fontSize__20;
  line-height: 1.2;
  color: $cbds-tkn-color__neutral--700;
}


.cbdsws-c-card__desc {
  margin: 0;
  font-size: $cbds-tkn-fontSize__20;
  line-height: 1.5;
  color: $cbds-tkn-color__neutral--700;
}


.cbdsws-c-card__continue {
  display: inline-flex;
  justify-content: flex-end;
  margin-top: auto;
  margin-bottom: -8px;
  padding-top: $cbds-tkn-space__4x;
  color: $cbds-tkn-color__blue--600;
  font-size: $cbds-tkn-fontSize__20;
  line-height: 1.2;

  &Icon {
    width: 20px;
    height: 20px;
    margin-left: 4px;
    fill: $cbds-tkn-color__neutral--700;
  }
}


.cbdsws-c-card__releaseTag {
  position: absolute;
  top: 0;
  right: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}


.cbdsws-c-card__media {
  margin: -1px -1px 0 -1px;
  // Auto needed to pull the figure -1px around t/r/l to be on top of card border
  width: auto;
  // width: 100%;
  // margin: $cbds-tkn-space__8x 0 0 0;
  // padding: 0 $cbds-tkn-space__4x 0 $cbds-tkn-space__4x;

  &Img {
    display: block;
    width: 100%;
    height: 180px;
  }

  &Icon {
    display: block;
    margin: 0 auto;
    width: 80px;
    height: 80px;
  }

  &--illustration {
    padding: $cbds-tkn-space__6x $cbds-tkn-space__4x;
    border-radius: ($cbds-tkn-borderRadius__md - 1) ($cbds-tkn-borderRadius__md - 1) 0 0;

    .cbdsws-c-card__mediaImg {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      max-height: 140px;
    }
  }

  &--component {
    // Component thumbs have 20px safe-zone around them. Space is adjust to accommodate optical centering.
    padding: $cbds-tkn-space__2x 0 0 0;
    border-radius: ($cbds-tkn-borderRadius__md - 1) ($cbds-tkn-borderRadius__md - 1) 0 0;

    .cbdsws-c-card__mediaImg {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      max-height: 240px;
    }
  }

  &--bgGreen700 {
    background-color: $cbds-tkn-color__green--700;
  }
}

// .cbdsws-c-card__mediaIcon {
//   display: block;
//   margin: 0 auto;
//   width: 80px;
//   height: 80px;
// }










// ==========================================================================
// Variant (Card or List)
// ==========================================================================


.cbdsws-c-card--list {
  $c: 'cbdsws-c-card';

  padding: 0;
  border: none;
  box-shadow: none;

  &,
  &:hover,
  &:focus,
  &:active {
    .#{$c}__heading {
      text-decoration: underline;
    }
  }

  .#{$c}__bar {
    display: none;
  }

  .#{$c}__content {
    padding: 0;
  }

  .#{$c}__continue {
    display: none;
  }
}







// ==========================================================================
// Sizes
// ==========================================================================


.cbdsws-c-card--small {
  $c: 'cbdsws-c-card';

  .#{$c}__heading {
    font-size: $cbds-tkn-fontSize__30;
    line-height: 1.3;
  }

  .#{$c}__title {
    + .#{$c}__body {
      margin-top: $cbds-tkn-space__2x;
    }
  }

  .#{$c}__continue {
    padding-top: $cbds-tkn-space__3x;
  }
}
