// ==========================================================================
// --------------------------------------------------------------------------
// #HEADINGS
// --------------------------------------------------------------------------
// ==========================================================================


.cbdsws-u-heading__small {
  font-family: $cbds-tkn-fontRole__heading;
  font-weight: $cbds-tkn-fontWeight__semiBold;
  font-size: $cbds-tkn-fontSize__50;
  margin: ($cbds-tkn-space__6x + 4) 0 $cbds-tkn-space__4x 0;
  line-height: 1.2;
  letter-spacing: -0.5px;
  color: $cbds-tkn-color__black;
  opacity: 1;
}
