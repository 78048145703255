.cbdsws-l-mobileMenuContainer {
  display: none;
  position: absolute;
  box-sizing: border-box;
  padding: $cbds-tkn-space__6x 0 0;
  background: $cbds-tkn-color__white;
  top: 0;
  left: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  box-shadow: 2px 2px 0 0 rgba($cbds-tkn-color__black, 0.04);
  z-index: 2;
}

.cbdsws-c-mobileLogo {
  transform: scale(0.75);
  margin-left: -$cbds-tkn-space__6x;
}

@media(max-width: $cbds-tkn-breakpoint__md) {
  .cbdsws-l-mainContainer {
    padding-top: 72px !important;
  }
  .cbdsws-l-sidebarContainer {
    position: absolute;
    top: 0;
    right: 0;
    flex: 0 0 0;
    max-width: 0px;
    overflow-x: auto;
    z-index: 3;
    display: none;

    &--expanded {
      flex: 0 0 330px;
      min-width: 330px;
    }

    &--visible {
      display: block;
    }
  }
  .cbdsws-l-mobileMenuContainer {
    display: flex;
  }
}
.cbdsws-l-sidebar__buttons .cbds-c-button {
  padding: 0;
}