// ==========================================================================
// --------------------------------------------------------------------------
// #COMPONENT OVERVIEW + STATUS
// --------------------------------------------------------------------------
// ==========================================================================

//
// Template is used ONLY on the Components Overview & Status pages to allow
// the card grid and status table to extend out to 1280px. It works because
// there is no fixed nav.
//


.cbdsws-tp-doc--compOverStatus.cbdsws-l-doc--noFixedNav {

  .Document-content .cbdsws-l-contentContainer__inner {
    max-width: none;
  }
}
