// ==========================================================================
// --------------------------------------------------------------------------
// #FIGURE-GRID
// --------------------------------------------------------------------------
// ==========================================================================


// .cbdsws-c-figureGrid__grid {
//   display: flex;
//   flex-flow: row wrap;
//   margin-top: -$cbds-tkn-space__8x;
// }


// .cbdsws-c-figureGrid__gridItem {
//   width: 100%;
//   margin-top: $cbds-tkn-space__8x;
// }


// .cbdsws-c-figureGrid__grid--col2 {
//   @include media('>=xs') {
//     margin-right: -$cbds-tkn-space__4x;
//     margin-left: -$cbds-tkn-space__4x;

//     .cbdsws-c-figureGrid__gridItem {
//       // Flexbugs #4: https://github.com/philipwalton/flexbugs#4-flex-shorthand-declarations-with-unitless-flex-basis-values-are-ignored
//       flex: 0 0 50%;
//       width: 360px;
//       padding-right: $cbds-tkn-space__4x;
//       padding-left: $cbds-tkn-space__4x;
//     }
//   }

//   @include media('<mii') {
//     .cbdsws-c-figureGrid__gridItem {
//       flex: inherit;
//     }
//   }
// }


.cbdsws-c-figureGrid__grid {
  display: grid;

  &--col2 {
    // grid-template-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    grid-row-gap: 32px;
    grid-column-gap: 32px;
    align-items: stretch;
  }

  &--col3 {
    // grid-template-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-row-gap: 32px;
    grid-column-gap: 32px;
    align-items: stretch;
  }

  &--col4 {
    // grid-template-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-row-gap: 32px;
    grid-column-gap: 32px;
    align-items: stretch;
  }
}


.cbdsws-c-figureGrid__gridItem {
  display: flex;

  // Playing with FLEX to make the images align using new CSS Grid parent
  .cbdsws-c-figure {
    justify-content: flex-start;
  }

  .cbdsws-c-figure__frame {
    height: auto;
  }
}





// .cbdsws-c-figureGrid__grid--col3 {
//   @include media('>=xs') {
//     margin-right: -$cbds-tkn-space__4x;
//     margin-left: -$cbds-tkn-space__4x;

//     .cbdsws-c-figureGrid__gridItem {
//       // Flexbugs #4: https://github.com/philipwalton/flexbugs#4-flex-shorthand-declarations-with-unitless-flex-basis-values-are-ignored
//       flex: 0 0 25%;
//       width: 360px;
//       padding-right: $cbds-tkn-space__4x;
//       padding-left: $cbds-tkn-space__4x;
//     }
//   }

//   @include media('<mii') {
//     .cbdsws-c-figureGrid__gridItem {
//       flex: inherit;
//     }
//   }
// }


// .cbdsws-c-figureGrid__grid--col4 {
//   @include media('>=xs') {
//     margin-right: -$cbds-tkn-space__4x;
//     margin-left: -$cbds-tkn-space__4x;

//     .cbdsws-c-figureGrid__gridItem {
//       // Flexbugs #4: https://github.com/philipwalton/flexbugs#4-flex-shorthand-declarations-with-unitless-flex-basis-values-are-ignored
//       flex: 0 0 25%;
//       width: 360px;
//       padding-right: $cbds-tkn-space__4x;
//       padding-left: $cbds-tkn-space__4x;
//     }
//   }

//   @include media('<mii') {
//     .cbdsws-c-figureGrid__gridItem {
//       flex: inherit;
//     }
//   }
// }






// ==========================================================================
// IE11 Compatibility
// ==========================================================================


// @media all and (-ms-high-contrast: none) {
//   .cbdsws-c-figureGrid__grid--col2 {
//     @include media('>=xs') {
//       .cbdsws-c-figureGrid__gridItem {
//         flex: 1 1 auto;
//         max-width: 50%;
//       }
//     }

//     @include media('<mii') {
//       .cbdsws-c-figureGrid__gridItem {
//         flex: inherit;
//         max-width: none;
//       }
//     }
//   }

//   .cbdsws-c-figure__frame {
//     display: block;
//   }
// }
