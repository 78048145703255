// ==========================================================================
// --------------------------------------------------------------------------
// #FIGURE
// --------------------------------------------------------------------------
// ==========================================================================


.cbdsws-c-figure {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.cbdsws-c-figure--autoHeight {
  height: auto;
}

.cbdsws-c-figure__frame {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: $cbds-tkn-space__4x;
  border: $cbds-tkn-borderWidth__xs solid $cbds-tkn-color__neutral--80;
  border-radius: $cbds-tkn-borderRadius__md;
  // Set overflow so if no frame padding, images appear w/ rounded corner.
  overflow: hidden;


  // BG Color Modifiers
  &--lightGray {
    background-color: $cbds-tkn-color__neutral--40;
  }


  // Inset Space Modifiers
  &--none {
    padding: 0;
  }
}


.cbdsws-c-figure__heading {
  margin: 0 0 $cbds-tkn-space__4x 0;
  font-family: $cbds-tkn-fontRole__ui;
  font-weight: $cbds-tkn-fontWeight__semiBold;
  font-size: $cbds-tkn-fontSize__40;
  line-height: 1.2;
  letter-spacing: -0.5px;
  color: $cbds-tkn-textColor__secondary;
}


.cbdsws-c-figure__frame + .cbdsws-c-figure__heading {
  margin: $cbds-tkn-space__4x 0 0 0;
}


.cbdsws-c-figure__image {
  display: block;
  max-width: 100%;
  height: auto;
  margin: auto;
}


.cbdsws-c-figure__caption {
  margin: $cbds-tkn-space__4x 0 0 0;
  font-size: $cbds-tkn-fontSize__20;
  line-height: 1.5;
  color: $cbds-tkn-color__neutral--700;

  > p {
    margin: 0;
  }

  > p:not(:first-child) {
    margin: $cbds-tkn-space__4x 0 0 0;
  }

  ol {
    margin-top: $cbds-tkn-space__2x;
  }
}


.cbdsws-c-figure__heading + .cbdsws-c-figure__caption {
  margin: $cbds-tkn-space__2x 0 0 0;
}
