// ==========================================================================
// --------------------------------------------------------------------------
// #COPY-BUTTON
// --------------------------------------------------------------------------
// ==========================================================================


h3 + .cbdsws-c-copyButton {
  &--withIcon {
    // Remove margin-top for the H3 so button aligns vertically
    margin-top: 0;

    @include media('<md') {
      // H3 Copy Button position to align it vertically to 1st line of text
      top: -$cbds-tkn-space__halfX;
    }
  }
}


.cbdsws-c-copyButton {
  $c: &;

  line-height: 1;
  cursor: pointer;
  appearance: none;

  &--withIcon {
    // Makes button vertical centered when inline
    margin-top: $cbds-tkn-space__halfX !important;
    margin-left: $cbds-tkn-space__2x !important;
    padding: $cbds-tkn-space__2x !important;
    vertical-align: top;
    opacity: 0.001;

    @include media('<md') {
      position: absolute;
      // Initial position is for the H2 Copy Button to align it vertically to 1st line of text
      top: 9px;
      right: -20px;
    }
  }


  &--colorChip {
    height: 20px;
    vertical-align: middle;

    &:focus {
      color: $cbds-tkn-color__black;
      text-decoration: underline;
    }

    &:hover {
      color: $cbds-tkn-color__black;
      text-decoration: underline;
    }

    &:active {
      color: $cbds-tkn-color__green;
      text-decoration: underline;
    }
  }
}


.cbdsws-c-copyButton__icon {
  width: 20px;
  height: 20px;
  fill: $cbds-tkn-color__black;
  pointer-events: none;
}
