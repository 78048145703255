// ==========================================================================
// --------------------------------------------------------------------------
// #ADA GUIDELINES
// --------------------------------------------------------------------------
// ==========================================================================

.cbdsws-c-adaGuidelines {

  .cbdsws-c-adaTextColorList {
    display: flex;
    padding-left: $cbds-tkn-space__0;
  }

  .cbdsws-c-adaTextColorListItem {
    list-style: none;
    min-width: 25%;
  }

  .cbdsws-c-adaTable__meaningHead {
    width: 33%;
  }

  .cbdsws-c-adaTableCell {
    vertical-align: top;
  }

  .cbdsws-c-adaTable__badge {
    display: block;
    padding: $cbds-tkn-space__halfX $cbds-tkn-space__4x;
    border-radius: $cbds-tkn-borderRadius__md;
    font-size: $cbds-tkn-fontSize__10;
    text-transform: uppercase;
    text-align: center;
    background-color: $cbds-tkn-backgroundColor__black;
    color: $cbds-tkn-color__white;
  }

}
