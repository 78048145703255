// ==========================================================================
// --------------------------------------------------------------------------
// #HERO
// --------------------------------------------------------------------------
// ==========================================================================

.cbdsws-c-hero {
  margin: (-$cbds-tkn-space__8x) (-$cbds-tkn-space__16x) 0;
  background-image: url("/src/assets/images/illustrations/home/home_background.png");
}


.cbdsws-c-hero__inner {
  max-width: 768px;
  padding: $cbds-tkn-space__16x 0 0 $cbds-tkn-space__16x;

  @media screen and (max-width: $cbds-tkn-breakpoint__sm) {
    padding: 0 $cbds-tkn-space__16x;
  }
}

.cbdsws-c-hero__heading {
  font-family: $cbds-tkn-fontFamily__firaSans;
  font-size: $cbds-tkn-fontSize__140;
  line-height: $cbds-tkn-lineHeight__auto;
  letter-spacing: -0.01em;
  color: $cbds-tkn-color__black;
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);

  @media screen and (max-width: $cbds-tkn-breakpoint__md) {
    line-height: 120px;
  }

  @media screen and (max-width: $cbds-tkn-breakpoint__sm) {
    font-size: 84px;
    line-height: 92px;
  }

  &--highlight {
    letter-spacing: -0.025em;
    color: $cbds-tkn-color__black;
    width: 733px;
    height: 77px;
    background: #4AFF75;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    @media screen and (max-width: $cbds-tkn-breakpoint__md) {
      width: 100%;
      height: 54px;
    }

    @media screen and (max-width: $cbds-tkn-breakpoint__sm) {
      width: 100%;
      height: 24px;
    }

  }

  &--label {
    font-family: $cbds-tkn-fontFamily__bobby;
    font-style: normal;
    font-weight: 700;
    font-size: 108px;
    display: inline-block;
    transform: translateY(-46px);

    @media screen and (max-width: $cbds-tkn-breakpoint__md) {
      font-size: 70px;
    }

    @media screen and (max-width: $cbds-tkn-breakpoint__sm) {
      font-size: 42px;
    }

  }
}



.cbdsws-c-hero__intro {
  margin: $cbds-tkn-space__8x 0 0 0;
  font-family: $cbds-tkn-fontRole__ui;
  font-weight: $cbds-tkn-fontWeight__light;
  font-size: $cbds-tkn-fontSize__70;
  line-height: 1.3;
}


.cbdsws-c-hero__actions {
  display: none;
  /* display: flex; Hidden until we have pages to link to */
  flex-wrap: wrap;
  margin: $cbds-tkn-space__8x 0 0 0 !important;
}


.cbdsws-c-hero__cta {
  margin-top: $cbds-tkn-space__2x !important;
  margin-right: $cbds-tkn-space__4x !important;
}
