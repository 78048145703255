// ==========================================================================
// --------------------------------------------------------------------------
// #ABLE PLAYER
// --------------------------------------------------------------------------
// ==========================================================================


.cbdsws-c-able {
  position: relative;
  border: 0;
  margin: 0;
  padding: 0;
}

