.cbdsws-c-navigation {
  $c: 'cbdsws-c-navigation';
  list-style-type: none !important;
  margin: $cbds-tkn-space__6x (-$cbds-tkn-space__4x) 0;
  padding: 0;

  &__list {
    margin: 0 0 $cbds-tkn-space__2x;
    padding: 0;
    list-style-type: none;
    display: none;

    .#{$c}__button {
      padding-left: 44px; // 2nd Level
      font-weight: $cbds-tkn-fontWeight__normal;
    }

    .#{$c}__list .#{$c}__button {
      padding-left: 56px; // 3rd Level
    }

    .#{$c}__list {
      .#{$c}__list .#{$c}__button {
        padding-left: 68px; // 4th level
      }
    }

    &--show {
      display: block;
    }
  }

  &__listItem {
    margin: 0;
  }

  &__button {
    justify-content: space-between !important;
    text-align: left !important;

    &:hover {
      background-color: $cbds-tkn-color__sage !important;
    }

    &--active {
      color: $cbds-tkn-color__green !important;
      font-weight: $cbds-tkn-fontWeight__semiBold !important;
      background-color: $cbds-tkn-color__white !important;

      &:visited {
        color: $cbds-tkn-color__green;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 5px;
        background-color: $cbds-tkn-color__green;
      }
    }

    &--expanded {
      color: $cbds-tkn-color__green;
    }
  }

  &__divider {
    margin: $cbds-tkn-space__2x 0;
    border: 1px solid $cbds-tkn-color__neutral--100;
  }
}
