// ==========================================================================
// --------------------------------------------------------------------------
// #ENVIRONMENT
// --------------------------------------------------------------------------
// ==========================================================================


// ==========================================================================
// Production
// ==========================================================================

.cbdsws-env--production {

  // Remove layout space due to hidden components nav group
  .Navigation-group {
    margin-top: 0;
  }
  .Navigation-group + .Navigation-group {
    margin-top: 0;
    margin-bottom: $cbds-tkn-space__12x;
  }

  // Remove padding from docs and component titles so navigation appears closer to header
  .Navigation .Tree-title {
    padding: 0;
  }
}
