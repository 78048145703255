// ==========================================================================
// --------------------------------------------------------------------------
// #CODE-SNIPPET
// --------------------------------------------------------------------------
// ==========================================================================


.cbdsws-c-codeSnippet {
  margin: $cbds-tkn-space__6x 0 0 0;
  border-width: $cbds-tkn-borderWidth__xs 0 0 0;
  border-radius: 0 0 ($cbds-tkn-borderRadius__md - 1) ($cbds-tkn-borderRadius__md - 1);
  border-style: solid;
  border-color: $cbds-tkn-color__neutral--100;
  position: relative;

  // code,
  // pre {
  //   margin: 0;
  //   padding: 0;
  //   font-size: 12px;
  //   line-height: 1.4;
  //   font-style: normal;
  //   color: $cbds-tkn-color__neutral--900;
  //   text-shadow: 0 1px $cbds-tkn-color__white;
  //   text-align: left;
  //   white-space: pre;
  //   word-spacing: normal;
  //   word-break: normal;
  //   word-wrap: normal;
  //   background: none;
  //   tab-size: 4;
  //   hyphens: none;
  // }

  // // Code blocks
  // pre {
  //   max-width: 1200px !important;
  //   max-height: 180px;
  //   padding: $cbds-tkn-space__4x;
  //   margin: 0;
  //   overflow: auto;
  //   border: none;
  //   // Fix bgColor effect on borders
  //   border-radius: 0 0 ($cbds-tkn-borderRadius__md - 1) ($cbds-tkn-borderRadius__md - 1);
  // }

  // pre > code {
  //   position: relative;
  // }

  .cbdsws-c-markdown__extended {
    padding: 0;
    pre, code {
      padding: 0;
      margin: 0;
      max-width: 1200px;
      // max-height: 180px;
      overflow: auto;
    }
    & > pre > div {
      margin: 0 !important;
    }
  }

  :not(pre) > code,
  pre {
    background: #fafafa;
  }

  // Inline code
  :not(pre) > code {
    padding: $cbds-tkn-space__2x;
    border-radius: $cbds-tkn-borderRadius__md;
    white-space: normal;
  }

  p {
    max-width: none !important;
    margin: 0;
  }

  // Removes -top we need for Fractal, used for inline code examples
  &--inline pre > code {
    top: 0;
  }
}


// .cbdsws-c-codeSnippet__actions {
//   position: relative;
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-end;
//   height: 54px;
//   padding: 0 $cbds-tkn-space__4x;
//   overflow: hidden;
//   background-color: $cbds-tkn-color__white;

//   &::after {
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     height: 2px;
//     background: $cbds-tkn-color__neutral--100;
//     content: '';
//     z-index: 0;
//   }
// }


.cbds-c-button.cbdsws-c-codeSnippet__copyCodeBtn {
  height: 36px;
  margin-right: -$cbds-tkn-space__2x;
  top: -1px; // vert-align perfectly
  align-self: center;

  position: absolute !important;
  top: 10px;
  right: 20px;
}

.cbdsws-c-codeSnippet__tabs.cbds-c-tabs {
  .cbds-c-tabs-nav {
    & > button {
      width: unset;
    }
  }
}
