// ==========================================================================
// --------------------------------------------------------------------------
// #BLOCKQUOTE
// --------------------------------------------------------------------------
// ==========================================================================

.cbdsws-c-blockquote {
  margin: $cbds-tkn-space__12x 0 $cbds-tkn-space__12x $cbds-tkn-space__8x !important; // TODO: Remove after re-factor some layout styles
  padding: $cbds-tkn-space__4x $cbds-tkn-space__6x;
  font-style: italic;
  font-size: $cbds-tkn-fontSize__50;
  line-height: 1.6;
  opacity: 1;
  border-radius: $cbds-tkn-borderRadius__md;
  border-left: $cbds-tkn-borderWidth__md solid $cbds-tkn-color__black;
  &, p {
    margin: 0;
    line-height: 1.5;
  }
  > footer {
    display: block;
    margin: $cbds-tkn-space__4x 0 0 0;
    font-size: $cbds-tkn-fontSize__30;
    line-height: 1.2;
    color: $cbds-tkn-color__neutral--700;
    &::before {
      content: "\2014 \00A0"; // em dash, nbsp
    }
  }
}
