.cbdsws-c-searchResult {
  margin: 16px 0 0;

  &__item {
    padding: 16px 0 32px;
    margin: 16px 0;
    border-bottom: 1px solid #E9E9E9;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__itemTitle {
    margin: 0 0 8px;

  }

  &__itemSummary {
    margin: 0 0 9px;
    font-weight: 300;
  }

  &__itemLink {
    margin: 0 0 8px;
    font-weight: 300;
    margin: 8px 0;
  }
}

.cbdsws-l-mobileSearchBarContainer {
  display: none;

  .cbdsws-c-searchBar {
    margin: 0;
    margin-bottom: -$cbds-tkn-space__6x;

    .cbdsws-c-searchBar__button {
      top: 6px;
    }
  }
}

@media(max-width: $cbds-tkn-breakpoint__md) {
  .cbdsws-l-mobileSearchBarContainer {
    display: block;
    flex: 1 0 100%;
  }
}
