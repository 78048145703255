// ==========================================================================
// --------------------------------------------------------------------------
// #WHATS NEW
// --------------------------------------------------------------------------
// ==========================================================================


.cbdsws-tp-doc--whatsNew .cbdsws-l-contentContainer {
  // Add more separation between month headings so it's not so crowded
  .cbdsws-c-header--smallGap {
    margin-top: $cbds-tkn-space__16x;
  }

  // Reset 1st heading level under the Year heading
  .cbdsws-c-header--largeGap + .cbdsws-c-header--smallGap {
    margin-top: $cbds-tkn-space__12x;
  }

  h4:not([class^="cbdsws"]) {
    margin: 0;
    padding: 0;
    font-family: $cbds-tkn-fontRole__ui;
    font-weight: $cbds-tkn-fontWeight__semiBold;
    font-size: $cbds-tkn-typeScale__600;
    line-height: 1.2;
    letter-spacing: normal;
  }

  h4:not([class^="cbdsws"]) + p {
    margin-top: $cbds-tkn-space__3x;
  }

  h5:not([class^="cbdsws"]) {
    margin-bottom: 0;
    font-family: $cbds-tkn-fontRole__ui;
    font-weight: $cbds-tkn-fontWeight__semiBold;
    font-size: $cbds-tkn-typeScale__500;
    line-height: 1.2;
    letter-spacing: normal;
  }

  h5:not([class^="cbdsws"]) + p {
    margin-top: $cbds-tkn-space__3x;
  }

  // Put more separation between 1st level of bullet points in
  // each month since these are the major highlights.
  > ul > li {
    margin-top: $cbds-tkn-space__6x;
  }
}
