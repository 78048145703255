// ==========================================================================
// --------------------------------------------------------------------------
// #CODE-DUO
// --------------------------------------------------------------------------
// ==========================================================================


//
// @TODO:
// RB: At some point in future I'd like to refactor CSS so the
// layout rules are separate from the styling and not separated
// using individual mq's within each BEM class selector.
//


.cbdsws-c-codeDuo {
  margin-top: $cbds-tkn-space__6x;

  background-color: $cbds-tkn-backgroundColor__white;
  background-clip: border-box;
  border: $cbds-tkn-borderWidth__xs solid $cbds-tkn-color__neutral--100;
  border-radius: $cbds-tkn-borderRadius__md;
  box-shadow: 2px 2px 0 0 rgba($cbds-tkn-color__black, 0.04);

  .cbdsws-c-codeDuo__preview,
  .cbdsws-c-codeDuo__snippet {
    margin-top: 0;
  }
}


.cbdsws-c-codeDuo__preview {
  $c: 'cbdsws-c-codeDuo__preview';
  // @include cbdsws-clearfix;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: $cbds-tkn-space__6x 0 0 0;
  border-width: $cbds-tkn-borderWidth__xs 0 0 0;
  border-radius: 0;
  border-style: solid;
  border-color: $cbds-tkn-color__neutral--100;

  .cbdsws-docs-background-dark {
    padding: 0 $cbds-tkn-space__3x;
    display: inline-block;
  }

  &:first-child {
    border-top-width: 0;
  }

  > *:first-child {
    margin-top: 0;
  }

  @include media('>=sm') {
    flex-wrap: nowrap;
  }
}


.cbdsws-c-codeDuo__previewExample {
  flex-shrink: 1;
  width: 100%;
  padding: $cbds-tkn-space__6x;
  overflow: hidden;
}


.cbdsws-c-codeDuo__previewSpec {
  min-width: 100%;
  max-width: 100%;
  margin-left: auto;
  padding: $cbds-tkn-space__4x $cbds-tkn-space__6x $cbds-tkn-space__6x $cbds-tkn-space__6x;
  border-radius: 0; // Fix bgColor overlap of parent border
  border-top: $cbds-tkn-borderWidth__xs dashed $cbds-tkn-color__neutral--100;
  background-color: rgba($cbds-tkn-backgroundColor__blue--lightest, .6);

  @include media('>=sm') {
    min-width: 180px;
    max-width: 240px;
    margin-left: auto;
    padding-right: $cbds-tkn-space__4x;
    padding-left: $cbds-tkn-space__4x;
    border-radius: 0 ($cbds-tkn-borderRadius__md - 1) 0 0; // Fix bgColor overlap of parent border
    border-top: none;
    border-left: $cbds-tkn-borderWidth__xs dashed $cbds-tkn-color__neutral--100;
  }

  // Optional sizes
  &--sm {
    @include media('>=sm') {
      min-width: 120px;
      max-width: 160px;
    }
  }
}


.cbdsws-c-codeDuo__previewSpecList {
  margin: 0;
  padding: 0;
  font-size: $cbds-tkn-fontSize__10;
  line-height: 16px;
  color: $cbds-tkn-color__neutral--900;
  list-style: none;

  &Item {
    margin: $cbds-tkn-space__2x 0 0 0;
    list-style: none;
  }

  &Key {
    font-weight: bold;
  }
}


.cbdsws-c-codeDuo__header {
  position: absolute;
  top: $cbds-tkn-space__1x;
  right: -$cbds-tkn-space__2x;
  padding: 1px $cbds-tkn-space__2x 1px $cbds-tkn-space__2x;
  font-weight: $cbds-tkn-fontWeight__semiBold;
  font-size: $cbds-tkn-fontSize__10;
  line-height: 16px;
  text-transform: uppercase;
  color: $cbds-tkn-color__neutral--900;
  background-color: rgba($cbds-tkn-color__white, .8);
  border-radius: $cbds-tkn-borderRadius__md;
  border: $cbds-tkn-borderWidth__xs solid $cbds-tkn-color__neutral--100;
  box-shadow: -1px 1px 0 0 rgba($cbds-tkn-color__black, 0.04);
}

.cbdsws-c-codeDuo__previewLink {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  right: 0;
  bottom: 0;

  // RB: Hack !importants to get around <a> color inheritance due to theme.
  color: $cbds-tkn-color__neutral--700 !important;

  &:focus {
    color: $cbds-tkn-color__neutral--900 !important;
  }

  &:hover {
    color: $cbds-tkn-color__neutral--900 !important;
  }

  &:active {
    color: $cbds-tkn-color__neutral--1100 !important;
  }
}

.cbdsws-c-codeDuo__previewLinkIcon {
  width: 20px;
  height: 20px;
  fill: currentColor;
}




// ==========================================================================
// Reset styles CBDS Components are inheriting due to Fractal overrides
// ==========================================================================

.cbdsws-c-codeDuo__previewExample {
  // Reset h1-h6
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
  }

  // Reset blockquote
  blockquote {
    border-radius: 0;
    border-left: none;
  }

  // Remove margin from <address> theme style
  address {
    margin: 0;
  }
}






// ==========================================================================
// Modifiers: OnDark Preview
// ==========================================================================

.cbdsws-c-codeDuo__preview--onDark {
  $c: 'cbdsws-c-codeDuo';

  background-color: $cbds-tkn-backgroundColor__green;

  .#{$c}__header {
    background-color: rgba($cbds-tkn-color__white, .94);
  }
}




// ==========================================================================
// Modifiers
// ==========================================================================


//
// Display Type: Type Scale
// ==========================================================================

.cbdsws-c-codeDuo--typeScale {
  $c: 'cbdsws-c-codeDuo';

  // Don't wrap the type scale examples
  .#{$c}__preview p {
    overflow: hidden;
    white-space: nowrap;
  }
}


//
// Display Type: Font Size
// ==========================================================================

.cbdsws-c-codeDuo--fontSize {
  $c: 'cbdsws-c-codeDuo';

  // Don't wrap the type scale examples
  .#{$c}__preview p {
    overflow: hidden;
    white-space: nowrap;
  }
}


//
// Display Type: Modal
// ==========================================================================

// Overrides to display Modal within the preview, opened by default, within
// the preview window. Without these updates, Modal would appear above
// the entire site UI.

.cbdsws-c-codeDuo--modal {
  $c: 'cbdsws-c-codeDuo';

  .#{$c}__previewExample {
    position: relative;
  }

  .#{$c}__preview .#{$c}__previewExample .cbds-c-modal {
    position: static;
    height: 100%;
    width: 100%;
  }

  .#{$c}__preview .#{$c}__previewExample .cbds-c-modal .cbds-c-modal__overlay {
    position: absolute;
  }
}


//
// Preview: Header
// ==========================================================================

.cbdsws-c-codeDuo__preview--header {
  $c: 'cbdsws-c-codeDuo__preview';

  // Don't wrap the type scale examples
  .#{$c}Spec {
    @include media('>=sm') {
      padding-top: $cbds-tkn-space__8x;
    }
  }
}


//
// Grid Docs Examples
// ==========================================================================

@mixin cbds-grid-col-boxShadow($gutter, $color) {
  box-shadow: inset calc($gutter / 2) 0 0 $color, inset calc(($gutter * -1) / 2) 0 $color;
}

//
// Optional CBDS Layout Grid vizualization
//
.cbdsws-c-codeDuo.has-layoutGridViz {

  .cbdsws-c-codeDuo__previewExample [class^="cbds-l-grid__col"] {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    font-weight: normal;
    background-color: rgba(239, 118, 33, 0.2);
    @include cbds-grid-col-boxShadow($cbds-tkn-grid__gutter--mobile, rgba(239, 118, 33, 0.2));
    outline: 1px solid rgba(239, 118, 33, 0.4);
    outline-offset: -1px;
    padding-bottom: 16px;
    padding-top: 16px;

    @media(min-width: $cbds-tkn-breakpoint__md) {
      @include cbds-grid-col-boxShadow($cbds-tkn-grid__gutter--tablet, rgba(239, 118, 33, 0.2))
    }

    @media(min-width: $cbds-tkn-breakpoint__xl) {
      @include cbds-grid-col-boxShadow($cbds-tkn-grid__gutter--desktop, rgba(239, 118, 33, 0.2))
    }
  }

  .cbdsws-c-codeDuo__previewExample {
    .cbds-l-grid--\@2xs,
    .cbds-l-grid--\@xs,
    .cbds-l-grid--\@sm {
      [class^="cbds-l-grid__col"] {
        @include cbds-grid-col-boxShadow($cbds-tkn-grid__gutter--mobile, rgba(239, 118, 33, 0.2));
      }
    }

    @media(min-width: $cbds-tkn-breakpoint__md) {
      .cbds-l-grid--\@md,
      .cbds-l-grid--\@lg {
        [class^="cbds-l-grid__col"] {
          @include cbds-grid-col-boxShadow($cbds-tkn-grid__gutter--tablet, rgba(239, 118, 33, 0.2))
        }
      }
    }

    @media(min-width: $cbds-tkn-breakpoint__xl) {
      .cbds-l-grid--\@xl,
      .cbds-l-grid--\@2xl,
      .cbds-l-grid--\@3xl,
      .cbds-l-grid--\@4xl {
        [class^="cbds-l-grid__col"] {
          @include cbds-grid-col-boxShadow($cbds-tkn-grid__gutter--desktop, rgba(239, 118, 33, 0.2))
        }
      }
    }
  }

  .cbdsws-c-codeDuo__previewExample > [class^="cbds-l-grid"] {
    @include cbds-grid-col-boxShadow($cbds-tkn-grid__gutter--mobile, rgba($cbds-tkn-backgroundColor__green, 0.2));
    outline: 1px solid rgba($cbds-tkn-backgroundColor__green, 0.4);

    @media(min-width: $cbds-tkn-breakpoint__md) {
      @include cbds-grid-col-boxShadow($cbds-tkn-grid__gutter--tablet, rgba($cbds-tkn-backgroundColor__green, 0.2))
    }

    @media(min-width: $cbds-tkn-breakpoint__xl) {
      @include cbds-grid-col-boxShadow($cbds-tkn-grid__gutter--desktop, rgba($cbds-tkn-backgroundColor__green, 0.2))
    }
  }

  .cbdsws-c-codeDuo__previewExample {
    > .cbds-l-grid--\@2xs,
    > .cbds-l-grid--\@xs,
    > .cbds-l-grid--\@sm {
      @include cbds-grid-col-boxShadow($cbds-tkn-grid__gutter--mobile, rgba($cbds-tkn-backgroundColor__green, 0.2));
    }

    @media(min-width: $cbds-tkn-breakpoint__md) {
      > .cbds-l-grid--\@md,
      > .cbds-l-grid--\@lg {
        @include cbds-grid-col-boxShadow($cbds-tkn-grid__gutter--tablet, rgba($cbds-tkn-backgroundColor__green, 0.2))
      }
    }

    @media(min-width: $cbds-tkn-breakpoint__xl) {
      > .cbds-l-grid--\@xl,
      > .cbds-l-grid--\@2xl,
      > .cbds-l-grid--\@3xl,
      > .cbds-l-grid--\@4xl {
        @include cbds-grid-col-boxShadow($cbds-tkn-grid__gutter--desktop, rgba($cbds-tkn-backgroundColor__green, 0.2))
      }
    }
  }


  .cbdsws-c-codeDuo__previewExample [class^="cbds-l-grid__col"] {
    color: #333;
  }

  .cbdsws-c-codeDuo__previewExample .cbds-l-grid__row--noGutters [class^="cbds-l-grid__col"] {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .cbdsws-c-codeDuo__previewExample .cbds-l-grid__row + .cbds-l-grid__row {
    margin-top: 16px;
  }

  .cbdsws-c-codeDuo--align .cbds-l-grid__row {
    background-color: rgba(255, 0, 0, 0.1);
    min-height: 120px;
  }

}
