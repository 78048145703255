// ==========================================================================
// --------------------------------------------------------------------------
// #SCREENSHOT LINK
// --------------------------------------------------------------------------
// ==========================================================================


.cbdsws-c-screenshotLink {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &--autoHeight {
    height: auto;
  }

  &__heading {
    margin: 0 0 $cbds-tkn-space__4x 0;
    font-family: $cbds-tkn-fontRole__ui;
    font-weight: $cbds-tkn-fontWeight__semiBold;
    font-size: $cbds-tkn-fontSize__40;
    line-height: 1.2;
    letter-spacing: -0.5px;
    color: $cbds-tkn-textColor__secondary;
  }

  &__image {
    display: block;
    max-width: 100%;
    height: auto;
  }

  &__layout {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    list-style: none;
    margin: $cbds-tkn-space__4x 0 0 0;
    padding: 0;

    &Icon {
      margin: 0 $cbds-tkn-space__2x 0 0;
      vertical-align: middle;
      vertical-align: text-bottom;

      &HTML {
        margin: 0 $cbds-tkn-space__2x 0 0;
      }
    }

    &Link {
      margin: 0 0 0 $cbds-tkn-space__8x;
      font-size: $cbds-tkn-fontSize__40;
      font-weight: $cbds-tkn-fontWeight__semiBold;
    }
  }
}
