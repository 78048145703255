// ==========================================================================
// --------------------------------------------------------------------------
// #DoDontGrid
// --------------------------------------------------------------------------
// ==========================================================================


.cbdsws-c-doDontGrid__grid {
  display: flex;
  flex-flow: row wrap;
  margin-top: -$cbds-tkn-space__8x;
}


.cbdsws-c-doDontGrid__gridItem {
  width: 100%;
  margin-top: $cbds-tkn-space__8x;
}


.cbdsws-c-doDontGrid__grid--col2 {
  @include media('>=xs') {
    margin-right: -$cbds-tkn-space__4x;
    margin-left: -$cbds-tkn-space__4x;

    .cbdsws-c-doDontGrid__gridItem {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#4-flex-shorthand-declarations-with-unitless-flex-basis-values-are-ignored
      flex: 0 0 40%;
      width: 360px;
      padding-right: $cbds-tkn-space__4x;
      padding-left: $cbds-tkn-space__4x;

      @media print {
        max-width: 325px;
      }
    }

    .cbdsws-c-doDont__frame {
      // The 135px is a guess based on the image that has the most height in the grid.
      // It might need to be adjusted on a case by case basis.
      flex: 1 0 135px;
    }
  }

  @include media('<940px') {
    .cbdsws-c-doDontGrid__gridItem {
      flex: inherit;
    }
  }
}






// ==========================================================================
// IE11 Compatibility
// ==========================================================================


@media all and (-ms-high-contrast: none) {
  .cbdsws-c-doDontGrid__grid--col2 {
    @include media('>=xs') {
      .cbdsws-c-doDontGrid__gridItem {
        flex: 1 1 auto;
        max-width: 40%;
      }

      .cbdsws-c-doDont__frame {
        flex: 1 1 auto;
      }
    }

    @include media('<940px') {
      .cbdsws-c-doDontGrid__gridItem {
        flex: inherit;
        max-width: none;
      }
    }
  }

  .cbdsws-c-figure__frame {
    display: block;
  }
}

