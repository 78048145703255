.cbdsws-c-header {
  &__title {
    font-family: $cbds-tkn-fontFamily__bobby !important;
    font-size: $cbds-tkn-fontSize__150;
    font-weight: $cbds-tkn-fontWeight__400;
    line-height: 90px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 $cbds-tkn-space__6x;

    @media screen and (max-width: $cbds-tkn-breakpoint__sm) {
      font-size: 50px;
      line-height: 56px;
    }

  }

  &__breadcrumbs {
    font-size: $cbds-tkn-fontSize__40;
    font-weight: $cbds-tkn-fontWeight__300;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 $cbds-tkn-space__2x;
  }

  &__releases {
    @media screen and (min-width: $cbds-tkn-breakpoint__sm) {
      display: flex;
    }
    padding: 8px;
    margin: $cbds-tkn-space__4x 0;
    align-items: center;

    &Label {
      font-size: $cbds-tkn-fontSize__40;
      font-weight: $cbds-tkn-fontWeight__400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      margin: 0 $cbds-tkn-space__4x 0 0;
    }

    &Link {
      margin: 0 $cbds-tkn-space__4x 0 0;
      font-weight: $cbds-tkn-fontWeight__600;

      @media screen and (max-width: $cbds-tkn-breakpoint__sm) {
        display: inline-flex;
      }

      .cbds-c-link {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      &__icon {
        margin: 0 $cbds-tkn-space__2x 0 0;
        vertical-align: middle;
        vertical-align: text-bottom;

        &HTML {
          margin: 0 $cbds-tkn-space__2x 0 0;
        }

        &Figma {
          margin: 0 $cbds-tkn-space__2x 0 0;
          vertical-align: middle;
          vertical-align: text-bottom;
          height: 26px;
        }
      }
    }
  }
}

.cbdsws-c-headerTabs {
  margin: $cbds-tkn-space__4x 0;
  white-space: nowrap;

  .cbds-c-tabs-nav {
    justify-content: start !important;
    .cbds-c-tabs__tab {
      flex: 0 !important;
    }
  }
  .cbds-c-tabPanels {
    display: none;
  }
}




