// ==========================================================================
// --------------------------------------------------------------------------
// #BUTTON - TOP
// --------------------------------------------------------------------------
// ==========================================================================


.cbdsws-c-buttonTop {
  $c: &;
  position: fixed;
  bottom: $cbds-tkn-space__8x;
  right: $cbds-tkn-space__6x;
  // IE11 bug fix
  height: 44px;
  padding: 10px;
  //color: $cbds-tkn-color__white;
  background-color: $cbds-tkn-color__neutral--40;
  border-radius: $cbds-tkn-borderRadius__md;
  border: $cbds-tkn-borderWidth__xs solid $cbds-tkn-color__orange--300;
  line-height: 1;
  z-index: 2;
  box-shadow: 2px 2px 0 0 rgba($cbds-tkn-color__black, 0.04);
  cursor: pointer;
  //appearance: none;
  opacity: 1;
  //pointer-events: none;
  transition: opacity 0.3s ease-in-out;

  &:focus {
    border-color: $cbds-tkn-color__neutral--300;
  }

  &:hover {
    color: $cbds-tkn-color__orange--700;
    border-color: $cbds-tkn-color__orange--700;
    box-shadow: none;
  }

  &:active {
    border-color: $cbds-tkn-color__neutral--700;
    box-shadow: none;
  }

  svg.cbds-c-icon {
    fill: #d93d00;
  }

  @media print {
    display: none;
  }
}

.cbdsws-c-buttonTop.is-visible {
  opacity: 1;
  pointer-events: auto;
}
