// ==========================================================================
// --------------------------------------------------------------------------
// #COLOR-CHIP
// --------------------------------------------------------------------------
// ==========================================================================


.cbdsws-c-colorChip {
  display: inline-flex;
  align-items: center;
  font-family: $cbds-tkn-fontRole__code;
  font-weight: $cbds-tkn-fontWeight__normal;
  font-size: $cbds-tkn-fontSize__10;
  line-height: 12px;
  text-transform: lowercase;
  pointer-events: none;

  &__swatch {
    align-self: center;
    width: 16px;
    height: 16px;
    margin-right: $cbds-tkn-space__1x;
    border-radius: $cbds-tkn-borderRadius__md - 1;
    border: $cbds-tkn-borderWidth__xs solid $cbds-tkn-color__neutral--80;
  }
}

.cbdsws-c-colorChip--lg {
  $c: 'cbdsws-c-colorChip';

  font-size: $cbds-tkn-fontSize__30;
  line-height: 18px;

  .#{$c}__swatch {
    width: 28px;
    height: 28px;
    margin-right: $cbds-tkn-space__1x;
  }
}
