/*! cbds-site @version */

// ==========================================================================
// --------------------------------------------------------------------------
// #CBDSWS
// --------------------------------------------------------------------------
// ==========================================================================


//
// CONTENTS
// --------------------------------------------------------------------------
//
// TOKENS
//
// SETTINGS
//
// TOOLS
//
// GENERIC
//
// ELEMENTS
//
// OBJECTS
//
// COMPONENTS
//
// TEMPLATES
//
// UTILITIES
//




//
// TOKENS
// ==========================================================================

@import '@cbds/cbds-design-tokens/dist/scss/cbds-design-tokens';




//
// SETTINGS
// ==========================================================================

@import 'settings/__all';
@import '@cbds/cbds-components/dist/scss/globals/_all';



//
// TOOLS
// ==========================================================================

@import 'tools/__all';




//
// OBJECTS
// ==========================================================================

@import 'objects/__all';




//
// COMPONENTS
// ==========================================================================

// Scope cbdsws to ensure Fractal styles don't override cbdsws components due
// to [dir="ltr"] on a ton of the selectors
[dir="ltr"] .cbdsws-l-contentContainer,
.cbdsws-l-contentContainer {
  @import 'components/__all';

  //
  // Temp way to import CBDS components into document pages.
  // TODO: Re-think how CBDS components get loaded in.
  //
  @import '@cbds/cbds-components/src/components/cbds-components/cbds-button/cbds-button';
  @import '@cbds/cbds-components/src/components/cbds-components/cbds-link/cbds-link';
}



//
// TEMPLATES
// ==========================================================================

@import 'templates/__all';




//
// UTILITIES
// ==========================================================================

// Scope cbdsws to ensure Fractal styles don't override cbdsws compnents due
// to [dir="ltr"] on a ton of the selectors
[dir="ltr"] .cbdsws-l-contentContainer,
.cbdsws-l-contentContainer {
  @import 'utilities/__all';
}
