// ==========================================================================
// --------------------------------------------------------------------------
// #ICON-CARD
// --------------------------------------------------------------------------
// ==========================================================================


.cbdsws-c-iconCard {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  word-wrap: break-word;
  color: $cbds-tkn-color__neutral--900;
  @include cbdsws-card-style;
}

.cbdsws-c-iconCard__media {
  height: 160px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: $cbds-tkn-color__neutral--40;
  border-radius: $cbds-tkn-borderRadius__md $cbds-tkn-borderRadius__md 0 0;
}

.cbdsws-c-iconCard__svg {
  box-sizing: border-box;
  display: block;
  width: 24px;
  height: 24px;

  &--xs {
    width: 16px;
    height: 16px;
  }
  &--sm {
    width: 24px;
    height: 24px;
  }
  &--md {
    width: 48px;
    height: 48px;
  }
}


.cbdsws-c-iconCard__body {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  padding: $cbds-tkn-space__4x;
}


.cbdsws-c-iconCard__actions {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: 250ms;
  opacity: 0;
}


.cbdsws-c-iconCard__heading {
  font-size: $cbds-tkn-fontSize__20;
  line-height: 1.2;
  color: $cbds-tkn-color__black;
  text-align: center;

  & ~ h5 {
    margin: $cbds-tkn-space__stack--2x;
  }
}


.cbdsws-c-iconCard__copyBtn {
  text-align: center;
  button {
    text-align: center;
  }
}


.cbdsws-c-iconCard__id {
  display: block;
  margin: $cbds-tkn-space__1x 0 0 0;
  font-size: $cbds-tkn-fontSize__10;
  line-height: 1;
  color: $cbds-tkn-color__neutral--600;
}
