// ==========================================================================
// --------------------------------------------------------------------------
// #CARD-STYLE
// --------------------------------------------------------------------------
// ==========================================================================


//
// Mixin for simple global card bg style for components
//

@mixin cbdsws-card-style($shadow: true) {
  background-color: $cbds-tkn-color__white;
  background-clip: border-box;
  border: $cbds-tkn-borderWidth__xs solid $cbds-tkn-color__neutral--100;
  border-radius: $cbds-tkn-borderRadius__md;

  @if $shadow {
    box-shadow: 2px 2px 0 0 rgba($cbds-tkn-color__black, 0.04);
  }

  @media print {
    border: $cbds-tkn-borderWidth__xs solid $cbds-tkn-color__neutral--100 !important;
  }
}
