.cbdsws-c-logo {
  position: relative;

  &__link {
    display: block;
  }

  &__logo {
    display: block !important;
    height: 35px;
  }

  &__text {
    width: auto;
    transform: translate(46px, 0);
    display: inline-block;
    color: #231f20;

    &__label {
      font-family: $cbds-tkn-fontFamily__bobby;
      font-size: $cbds-tkn-fontSize__60;
      font-weight: $cbds-tkn-fontWeight__400;
      color: #231f20;
      line-height: 34px;
      letter-spacing: 0em;
      text-align: left;
      position: relative;
      z-index: 2;
    }

    &__highlight {
      position: absolute;
      background: #4AFF75;
      bottom: 6px;
      right: -3px;
      left: -3px;
      top: 11px;
      border-radius: 3px;
    }
  }

  img {
    width: 100%;
  }
}

@media(max-width: $cbds-tkn-breakpoint__xs) {

  // .cbdsws-l-contentContainer .cbdsws-c-hero__heading {
  //   font-size: 52px !important;
  //   line-height: 80px !important;
  //   margin-top: 0;
  // }

  span.cbdsws-c-hero__heading--label {
    font-size: 28px !important;
  }

}
