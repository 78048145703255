// ==========================================================================
// --------------------------------------------------------------------------
// #GLOBAL
// --------------------------------------------------------------------------
// ==========================================================================


//
// The global settings file contains any project-wide variables;
// things that need to be made available to the entire codebase.
//


//
// Breakpoints (Uses include-media plugin - https://include-media.com/)
// ==========================================================================

$breakpoints: (
  'xxs': 320px,
  'xs': 540px,
  'sm': 640px,
  'mi': 768px,
  'mii': 940px,
  'md': 1024px,
  'lg': 1280px,
  'xl': 1280px,
) !default;

$media-expressions: (
  'ie11': '(-ms-high-contrast: none)'
) !default;





// ==========================================================================
// Font Token Vars
// ==========================================================================


//
// Family
// ==========================================================================

// $cbdsws__fontFamily--text: $cbds-tkn-fontFamily__text;
// $cbdsws__fontFamily--heading: $cbds-tkn-fontFamily__heading;
// $cbdsws__fontFamily--citiSans: $cbds-tkn-fontFamily__citiSans;






// ==========================================================================
// Color Token Vars
// ==========================================================================


//
// Color Palette
// ==========================================================================


// Transparent
$cbdsws-baseColor__transparent: 'transparent';
$cbds-tkn-color__blackTransparent40: rgba($cbds-tkn-color__black, 0.4);


//
// Line Color
// ==========================================================================

$cbdsws-line__color: $cbds-tkn-color__neutral--80;


//
// Logo Color
// ==========================================================================

$cbdsws-logo__tagColor: $cbds-tkn-color__white;


//
// Text Color
// ==========================================================================


//
// Interactive Color
// ==========================================================================

$cbdsws-interactiveColor__primary--normal: $cbds-tkn-color__orange;
$cbdsws-interactiveColor__primary--hover: $cbds-tkn-color__orange--700;
$cbdsws-interactiveColor__primary--active: $cbds-tkn-color__orange--900;
$cbdsws-interactiveColor__primary--focus: $cbds-tkn-color__orange--700;





// ==========================================================================
// Components
// ==========================================================================


//
// Site Header
// ==========================================================================

$cbdsws-header__height: 56px;





// ==========================================================================
// Print
// ==========================================================================


@media print {

  h1,
  h2,
  h3,
  h4,
  h5 {
    page-break-after: avoid;
  }

  table {
    table-layout: fixed;
  }

  img {
    max-height: 500px;
    object-fit: contain;
  }
}
