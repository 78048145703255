// ==========================================================================
// --------------------------------------------------------------------------
// #BUTTON - PAGINATE
// --------------------------------------------------------------------------
// ==========================================================================

.cbds-next-and-back-nav {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 28px;

  .cbdsws-c-buttonPrev,
  .cbdsws-c-buttonNext {
    $c: &;
    height: 44px;
    padding: 10px;
    background-color: $cbds-tkn-color__neutral--40;
    border-radius: $cbds-tkn-borderRadius__md;
    border: $cbds-tkn-borderWidth__xs solid $cbds-tkn-color__orange--300;
    line-height: 1.5;
    box-shadow: 2px 2px 0 0 rgba($cbds-tkn-color__black, 0.04);
    cursor: pointer;


    &:focus {
      border-color: $cbds-tkn-color__neutral--300;
    }

    &:hover {
      color: $cbds-tkn-color__orange--700;
      border-color: $cbds-tkn-color__orange--700;
      box-shadow: none;
    }

  }

  .cbdsws-c-buttonNext.is-visible,
  .cbdsws-c-buttonPrev.is-visible {
    opacity: 1;
    pointer-events: auto;
  }

  svg.cbds-c-icon {
    fill: #d93d00;
  }

  button[disabled],
  button[aria-disabled=true] {
    background-color: $cbds-tkn-color__neutral--100;
    border: 1px solid $cbds-tkn-color__neutral--100;
    cursor: not-allowed;

    svg.cbds-c-icon {
      fill: $cbds-tkn-color__neutral--700;
    }

    &:hover {
      border-color: $cbds-tkn-color__neutral--100;
      box-shadow: none;
    }
  }

}