// ==========================================================================
// --------------------------------------------------------------------------
// #SPACE
// --------------------------------------------------------------------------
// ==========================================================================


.cbdsws-u-sp__mt2x {
  margin-top: $cbds-tkn-space__2x;
}


.cbdsws-u-sp__mt4x {
  margin-top: $cbds-tkn-space__4x;
}


.cbdsws-u-sp__mt6x {
  margin-top: $cbds-tkn-space__6x;
}


.cbdsws-u-sp__mt8x {
  margin-top: $cbds-tkn-space__8x;
}


.cbdsws-u-sp__mt12x {
  margin-top: $cbds-tkn-space__12x;
}


.cbdsws-u-sp__mb16 {
  margin-bottom: $cbds-tkn-space__16x;
}


.cbdsws-u-sp__-mt8 {
  margin-top: -$cbds-tkn-space__8x;
}
