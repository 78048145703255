// ==========================================================================
// --------------------------------------------------------------------------
// #HOME PAGE
// --------------------------------------------------------------------------
// ==========================================================================


.cbdsws-tp-doc--home {
  padding: 0 0 $cbds-tkn-space__8x 0;
  max-width: none;
  background-color: $cbds-tkn-color__neutral--40;

  .Document-content {
    margin: 0;
    padding: 0;
    max-width: none;
  }

  .cbdsws-l-contentContainer {
    max-width: none;
  }

  .cbdsws-l-contentContainer__inner {
    max-width: none;
    margin-right: 0;
  }
}

.cbdsws-c-exploreDigitalFoundations {
  margin-top: $cbds-tkn-space__16x;
}

.cbdsws-c-latestNewsReleases {
  margin-top: $cbds-tkn-space__16x;
}

.cbdsws-c-whatsHappening__btnViewAll {
  margin-top: $cbds-tkn-space__8x !important;
}
