// ==========================================================================
// --------------------------------------------------------------------------
// #CALLOUT
// --------------------------------------------------------------------------
// ==========================================================================


// ==========================================================================
// Base Layout & Styles
// ==========================================================================


.cbdsws-c-callout {
  // @TODO: Control mt in the layouts container vs hard-code into component
  @include cbds-box-sizing;

  margin-top: $cbds-tkn-space__6x;
  display: flex;
  position: relative;
  width: 100%;
  color: $cbds-tkn-color__neutral--700;
  @include cbdsws-card-style;
  // Needed to round the bar
  overflow: hidden;

  &::before {
    top: 0;
    left: 0;
    right: 0;
    content: '';
    width: auto;
    height: 4px;
    position: absolute;
    background-color: $cbds-tkn-color__neutral--900;
  }
}


.cbdsws-c-callout__icon {
  display: block;
  position: relative;
  top: -2px;
  width: 24px;
  height: 24px;
  fill: $cbds-tkn-color__neutral--900;
}


.cbdsws-c-callout__eyebrow {
  margin: 2px 0 0 0;
  font-family: $cbds-tkn-fontRole__heading;
  font-size: $cbds-tkn-fontSize__10;
  line-height: 16px;
  font-weight: $cbds-tkn-fontWeight__semiBold;
  color: $cbds-tkn-color__neutral--900;
}


.cbdsws-c-callout__jira {
  display: inline-block;
  font-family: $cbds-tkn-fontRole__ui;
  font-size: $cbds-tkn-fontSize__10;
  line-height: 16px;
  color: $cbds-tkn-color__neutral--700;

  &--link {
    cursor: pointer;

    &:focus {
      text-decoration: underline;
      color: $cbds-tkn-color__black;
    }

    &:hover {
      text-decoration: underline;
      color: $cbds-tkn-color__black;
    }

    &:active {
      text-decoration: underline;
      color: $cbds-tkn-color__black;
    }
  }
}


.cbdsws-c-callout__heading {
  margin: 0;
  font-family: $cbds-tkn-fontRole__heading;
  font-size: $cbds-tkn-fontSize__30;
  line-height: 24px;
  font-weight: $cbds-tkn-fontWeight__semiBold;
  color: $cbds-tkn-color__neutral--900;
}


.cbdsws-c-callout__desc {
  margin-top: $cbds-tkn-space__1x;
  font-size: $cbds-tkn-fontSize__20;
  line-height: 1.5;
  color: $cbds-tkn-color__neutral--700;

  & > p {
    margin: 8px 0 0 0;

    &:first-child {
      margin-top: 0;
    }
  }

  & > ul {
    margin-top: 0;
  }
}


.cbdsws-c-callout__rail {
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  max-width: 48px;
  // Less top padding to pull icon up a bit
  // Less bot padding to account for weird box bug
  padding: 16px 8px 12px 12px;
}


.cbdsws-c-callout__body {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  padding: 16px 16px 16px 0;
}






// ==========================================================================
// Type(s)
// ==========================================================================


//
// Note, Warning
// ==========================================================================

.cbdsws-c-callout--note,
.cbdsws-c-callout--warning {
  $c: 'cbdsws-c-callout';

  background-color: $cbds-tkn-color__gold--40;

  &::before {
    background-color: $cbds-tkn-color__gold--700;
  }

  .#{$c}__eyebrow {
    color: $cbds-tkn-color__gold--700;
  }

  .#{$c}__icon {
    fill: $cbds-tkn-color__gold--700;
  }
}




//
// In Queue
// ==========================================================================

.cbdsws-c-callout--queue {
  $c: 'cbdsws-c-callout';

  background-color: $cbds-tkn-color__neutral--40;

  &::before {
    background-color: $cbds-tkn-color__neutral--700;
  }

  .#{$c}__eyebrow {
    color: $cbds-tkn-color__neutral--700;
  }

  .#{$c}__icon {
    fill: $cbds-tkn-color__neutral--700;
  }
}




//
// To-do, Info
// ==========================================================================

.cbdsws-c-callout--todo,
.cbdsws-c-callout--info {
  $c: 'cbdsws-c-callout';

  background-color: $cbds-tkn-color__fadedBlue--40;

  &::before {
    background-color: $cbds-tkn-color__fadedBlue--700;
  }

  .#{$c}__eyebrow {
    color: $cbds-tkn-color__fadedBlue--700;
  }

  .#{$c}__icon {
    fill: $cbds-tkn-color__fadedBlue--700;
  }
}




//
// Deprecation, Breaking, Error
// ==========================================================================

.cbdsws-c-callout--breaking,
.cbdsws-c-callout--deprecation,
.cbdsws-c-callout--error {
  $c: 'cbdsws-c-callout';

  background-color: $cbds-tkn-color__red--40;

  &::before {
    background-color: $cbds-tkn-color__red--700;
  }

  .#{$c}__eyebrow {
    color: $cbds-tkn-color__red--700;
  }

  .#{$c}__icon {
    fill: $cbds-tkn-color__red--700;
  }
}




//
// Success, New
// ==========================================================================

.cbdsws-c-callout--new,
.cbdsws-c-callout--success {
  $c: 'cbdsws-c-callout';

  background-color: $cbds-tkn-color__green--40;

  &::before {
    background-color: $cbds-tkn-color__green--700;
  }

  .#{$c}__eyebrow {
    color: $cbds-tkn-color__green--700;
  }

  .#{$c}__icon {
    fill: $cbds-tkn-color__green--700;
  }
}
