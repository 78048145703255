
.cbdsws-c-searchBar {
  position: relative;
  margin-top: $cbds-tkn-space__6x;

  &__input {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 36px;
    padding: $cbds-tkn-space__1x $cbds-tkn-space__6x $cbds-tkn-space__1x $cbds-tkn-space__2x;
    background: $cbds-tkn-color__white;
    border: 2px solid $cbds-tkn-borderColor__neutral--dark;
    border-radius: $cbds-tkn-borderRadius__md;
  }

  &__button {
    position: absolute;
    top: 0;
    right: 0;
    height: 36px;
    background: none;
    border: 0;
    padding: 0 $cbds-tkn-space__2x;

    .cbdsws-c-searchIcon {
      width: 24px;
      height: 24px;
      fill: $cbds-tkn-color__neutral--800;
    }

    &:disabled {
      .cbdsws-c-searchIcon {
        fill: $cbds-tkn-color__neutral--500;
      }
    }
  }
}
