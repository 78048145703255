// ==========================================================================
// --------------------------------------------------------------------------
// #COLOR-CARD
// --------------------------------------------------------------------------
// ==========================================================================

.cbdsws-c-colorCard {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  word-wrap: break-word;
  color: $cbds-tkn-color__neutral--900;
  @include cbdsws-card-style;

  @media screen and (max-width: $cbds-tkn-breakpoint__sm) {
    width: 95%;
  }
}
.cbdsws-c-colorCard__color {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 148px;
  width: 100%;
  background-color: $cbds-tkn-color__neutral--900;
  border-radius: $cbds-tkn-borderRadius__md $cbds-tkn-borderRadius__md 0 0;

  @media print {
    -webkit-print-color-adjust: exact !important;
  }
}

.cbdsws-c-colorCard__body {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  padding: 0 $cbds-tkn-space__4x;
  border-top: $cbds-tkn-borderWidth__xs solid $cbds-tkn-color__neutral--200;
}


.cbdsws-c-colorCard__meta {
  margin: $cbds-tkn-space__3x 0 20px 0;
  padding: 0 !important;
  font-size: $cbds-tkn-fontSize__20;
  line-height: 2;
  list-style: none !important;

    .cbdsws-c-colorCard__metaItem {
      font-weight: $cbds-tkn-fontWeight__600;
  }
}

.cbdsws-c-colorCard__title {
  margin-top: 16px;
  margin-bottom: $cbds-tkn-space__0;
  font-family: $cbds-tkn-fontRole__ui;
  font-weight: $cbds-tkn-fontWeight__900;
  font-size: $cbds-tkn-fontSize__40;
  line-height: 28px;
  color: $cbds-tkn-color__black;
}

.cbdsws-c-colorCard__table {
  padding: 10px;
  border-top: 1px solid #e9e9e9;
  flex: 1;

  &:first-of-type {
    border-right: 1px solid #e9e9e9;
  }
}

.cbdsws-c-colorCard__contrast {
  font-size: $cbds-tkn-fontSize__20;
  line-height: 1.2;
  color: $cbds-tkn-color__neutral--700;
  margin: 0;

    tr, th, td {
      background: white;
      border: none;
      font-weight: normal;
      vertical-align: middle;
    }

}

.cbdsws-c-colorCard__body__status {
  display: flex;
}

.cbdsws-c-colorCardAda__level--black,
.cbdsws-c-colorCardAda__level--white {
  padding: 6px 12px;
  border: 1px solid #eee;
  color: #000;
}
.cbdsws-c-colorCardAda__level--white {
  color: #fff;
}


// // ==========================================================================
// // ADA  Group
// // ==========================================================================


.cbdsws-c-colorCardAdaGroup {
  display: flex;
  justify-content: space-around;
  padding: 0;
  font-size: $cbds-tkn-fontSize__20;
  display: flex;
}

.cbdsws-c-colorCardAdaTagGroup__ratio {
  width: 100%;
  margin: $cbds-tkn-space__1x $cbds-tkn-space__halfX 0 $cbds-tkn-space__halfX;
  font-family: $cbds-tkn-fontRole__ui;
  font-size: $cbds-tkn-typeScale__200;
  line-height: 14px;
  color: $cbds-tkn-color__neutral--100;
  text-align: center;
  background: $cbds-tkn-color__black;
}

