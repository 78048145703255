// ==========================================================================
// --------------------------------------------------------------------------
// #BUTTON-INLINE-CODE
// --------------------------------------------------------------------------
// ==========================================================================


.cbdsws-c-inlineCodeBtn {
  $c: &;

  position: relative;
  min-height: 20px;
  padding: $cbds-tkn-space__halfX;
  font-family: $cbds-tkn-fontRole__code;
  font-size: .86em;
  line-height: 1.4; //inherit
  text-align: left;
  color: $cbds-tkn-color__orange--700;
  background: $cbds-tkn-color__neutral--40;
  border: $cbds-tkn-borderWidth__xs solid $cbds-tkn-color__neutral--100;
  border-radius: $cbds-tkn-borderRadius__sm;
  cursor: pointer;
  appearance: none;

  &:focus {
    color: $cbds-tkn-color__black;
    text-decoration: underline;
    border-color: $cbds-tkn-color__neutral--300;
  }

  &:hover {
    color: $cbds-tkn-color__black;
    text-decoration: underline;
    border-color: $cbds-tkn-color__neutral--300;
  }

  &:focus > .cbdsws-c-inlineCodeBtn__icon,
  &:hover > .cbdsws-c-inlineCodeBtn__icon {
    opacity: 1;
    z-index: 1;
  }

  &:active {
    color: $cbds-tkn-color__green;
    text-decoration: underline;
    border-color: $cbds-tkn-color__neutral--700;
  }
}


.cbdsws-c-inlineCodeBtn__code,
.cbdsws-c-inlineCodeBtn__icon {
  pointer-events: none;
}


.cbdsws-c-inlineCodeBtn__code {
  font-family: $cbds-tkn-fontRole__code;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  border: none;
}


.cbdsws-c-inlineCodeBtn__icon {
  width: 20px !important;
  height: 20px !important;
  position: absolute !important;
  top: 0;
  right: -24px;
  fill: $cbds-tkn-color__neutral--700;
  opacity: 0;
  box-shadow: inset 0 0 0 6px $cbds-tkn-color__white;
}


.cbdsws-c-inlineCodeBtn__text {
  display: inline-flex;
  padding-left: $cbds-tkn-space__halfX;
  transform: translateY(-3px);
  pointer-events: none;
}






// ==========================================================================
// Snippet Type
// ==========================================================================


.cbdsws-c-inlineCodeBtn--snippet {
  .cbds-c-button__label {
    text-decoration: underline;
  }
  padding: 3px $cbds-tkn-space__2x 3px $cbds-tkn-space__1x;
  font-family: $cbds-tkn-fontRole__ui;
  font-size: $cbds-tkn-fontSize__10;
  color: $cbds-tkn-color__neutral--900;
  background-color: transparent;
  border-color: transparent;

  &:focus {
    background-color: $cbds-tkn-color__white;
    border-color: $cbds-tkn-color__neutral--300;
  }

  &:hover {
    background-color: $cbds-tkn-color__white;
    border-color: $cbds-tkn-color__neutral--300;
  }

  &:active {
    border-color: $cbds-tkn-color__neutral--700;
  }
}

.cbdsws-c-inlineCodeBtn__icon--snippet {
  position: static;
  opacity: 1;
  box-shadow: none;
}






// ==========================================================================
// Token Type
// ==========================================================================


.cbdsws-c-inlineCodeBtn--token {
  min-height: 24px;
  // Vert padding reflects inheriting line height for consistency
  padding: 2px 10px 2px $cbds-tkn-space__6x;
  // Set specific line-height so token doesn't inherit parent and have
  // inconsistent vert space
  line-height: 1.2;
  border-style: dashed;
  border-radius: $cbds-tkn-borderRadius__lg + 4;
  border-color: $cbds-tkn-color__orange--200;
  background-color: $cbds-tkn-color__orange--40;

  &::before {
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    top: 3px;
    left: 6px;
    background-image: url('../assets/images/components/inlineCodeBtn__token.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: $cbds-tkn-space__4x $cbds-tkn-space__4x;
  }

  &:focus,
  &:hover {
    border-color: $cbds-tkn-color__orange--400;
  }

  &:focus::before,
  &:hover::before {
    animation-name: tokenFlip;
    animation-duration: .6s;
  }
}

@keyframes tokenFlip {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
