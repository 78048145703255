// ==========================================================================
// --------------------------------------------------------------------------
// #SECTION
// --------------------------------------------------------------------------
// ==========================================================================


.cbdsws-c-section {
  display: flex;
  flex-flow: row wrap;
  margin: $cbds-tkn-space__6x 0 0 0;
}


.cbdsws-c-section__inner {
  max-width: 1184px;
  padding: $cbds-tkn-space__16x $cbds-tkn-space__6x;

  // 1st mobile breakpoint based on header
  @include media('>=xs') {
    padding: $cbds-tkn-space__16x $cbds-tkn-space__12x;
  }
}


.cbdsws-c-section__block {
  width: 100%;
  margin-top: $cbds-tkn-space__2x;
}


.cbdsws-c-section__heading {
  margin: 0;
  font-family: $cbds-tkn-fontRole__heading;
  font-weight: $cbds-tkn-fontWeight__semiBold;
  font-size: $cbds-tkn-fontSize__100;
  line-height: 1.2;
  letter-spacing: -1px;
  color: $cbds-tkn-color__black;

  &--alignCenter {
    text-align: center;
  }
}


.cbdsws-c-section__intro {
  margin: $cbds-tkn-space__8x 0 0 0;
  font-family: $cbds-tkn-fontRole__ui;
  font-weight: $cbds-tkn-fontWeight__normal;
  font-size: $cbds-tkn-fontSize__40;
  line-height: 1.5;
  color: $cbds-tkn-color__black;

  &--alignCenter {
    text-align: center;
  }
}


.cbdsws-c-section--col2 {
  @include media('>=xs') {
    margin-right: -$cbds-tkn-space__4x;
    margin-left: -$cbds-tkn-space__4x;

    .cbdsws-c-section__block {
      display: flex;
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#4-flex-shorthand-declarations-with-unitless-flex-basis-values-are-ignored
      flex: 0 0 50%;
      flex-direction: column;
      width: 360px;
      padding-right: $cbds-tkn-space__4x;
      padding-left: $cbds-tkn-space__4x;
    }
  }
}


.cbdsws-c-section--my0 {
  margin-top: 0;
  margin-bottom: 0;
}


.cbdsws-c-section--neutral98 {
  background-color: $cbds-tkn-color__neutral--40;
}


.cbdsws-c-section--borderT {
  border-top: $cbds-tkn-borderWidth__xs solid $cbds-tkn-color__neutral--80;
}


.cbdsws-c-section--centered {
  text-align: center;
}


.cbdsws-c-section--figure,
.cbdsws-c-section--actionCardGrid {
  @media print {
    page-break-inside: avoid;
  }
}





// ==========================================================================
// Specific Nested Component Layout Tweaks
// ==========================================================================

//
// @NOTE:
// Re-factor at some point to use universal classes
//

.cbdsws-c-section .cbdsws-c-postCardGrid,
.cbdsws-c-section .cbdsws-c-actionCardGrid {
  margin-top: $cbds-tkn-space__6x;
}





// ==========================================================================
// IE11 Compatibility
// ==========================================================================


@media all and (-ms-high-contrast: none) {
  .cbdsws-c-section__inner {
    width: 100%;
  }
}
