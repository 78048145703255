// ==========================================================================
// --------------------------------------------------------------------------
// #TOKENS TABLE ROW EXAMPLE
// --------------------------------------------------------------------------
// ==========================================================================




// ==========================================================================
// Color
// ==========================================================================


.cbdsws-c-tokensTable__color {
    min-width: 140px;
    max-width: 240px;
    height: 40px;
    border-radius: $cbds-tkn-borderRadius__md;
    border: $cbds-tkn-borderWidth__xs solid $cbds-tkn-borderColor__neutral--lighter;

    @media print {
      -webkit-print-color-adjust: exact !important;
      min-width: 80px;
      max-width: 80px;
    }
  }


  //
  // Color - Text
  // ==========================================================================

  .cbdsws-c-tokensRow__textColor {

    .cbdsws-c-tokensTable__color {
      position: relative;
      width: 74%;
      margin-right: $cbds-tkn-space__12x;

      &:after {
        content: 'Aa';
        display: block;
        position: absolute;
        right: -$cbds-tkn-space__12x;
        width: 40px;
        font-size: $cbds-tkn-fontSize__100;
        line-height: 1;
      }
    }
  }



  // ==========================================================================
  // Border Color
  // ==========================================================================


  .cbdsws-c-tokensTable__borderColor {
    min-width: 140px;
    max-width: 140px;
    height: 40px;
    background-color: $cbds-tkn-backgroundColor__white;
    border-radius: $cbds-tkn-borderRadius__md;
    border: $cbds-tkn-borderWidth__sm solid $cbds-tkn-borderColor__neutral--lighter;
  }



  // ==========================================================================
  // Border Width
  // ==========================================================================


  .cbdsws-c-tokensTable__borderWidth {
    min-width: 140px;
    max-width: 140px;
    height: 40px;
    background-color: $cbds-tkn-backgroundColor__neutral--lightest;
    border-radius: $cbds-tkn-borderRadius__md;
    border: $cbds-tkn-borderWidth__sm solid $cbds-tkn-borderColor__orange;
  }




  // ==========================================================================
  // Border Radius
  // ==========================================================================


  .cbdsws-c-tokensTable__borderRadius {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    background-color: $cbds-tkn-backgroundColor__neutral--lightest;
    border: $cbds-tkn-borderWidth__sm solid $cbds-tkn-borderColor__orange;
  }




  // ==========================================================================
  // Size
  // ==========================================================================


  .cbdsws-c-tokensTable__size {
    margin: auto;
    background-color: $cbds-tkn-backgroundColor__black;

    @media screen and (max-width: $cbds-tkn-breakpoint__md) {
      margin: auto 15px;
    }
  }






  // ==========================================================================
  // Space
  // ==========================================================================

  .cbdsws-c-tokensTable__spaceCntr {
    width: 256px;
  }


  //
  // Base
  // ==========================================================================

  .cbdsws-c-tokensTable__space--base {
    height: 24px;
    color: #d50000;
    background: lighten(#ff8a80, 10%);
    border: $cbds-tkn-borderWidth__xs solid lighten(#ff5252, 10%);
    box-sizing: content-box;
  }


  //
  // Inline
  // ==========================================================================

  .cbdsws-c-tokensTable__spaceInlineInner {
    display: flex;
    height: 48px;
  }

  .cbdsws-c-tokensTable__spaceInlineSpecBlock {
    display: flex;
    color: #64dd17;
    background: lighten(#ccff90, 10%);
    // Set to 0 so it has about same contrast as other borders
    border: $cbds-tkn-borderWidth__xs solid lighten(#b2ff59, 0%);

    &--0,
    &--none {
      border: none;
    }
  }

  .cbdsws-c-tokensTable__spaceInlineElement {
    width: 48px;
    height: 48px;
    background-color: $cbds-tkn-backgroundColor__white;
    border-width: $cbds-tkn-borderWidth__xs;
    border-style: dashed;
    border-color: $cbds-tkn-borderColor__neutral--light;

    // Move position of element to left of space
    &--left {
      order: -1;
      // border-right-width: 0;
    }

    &--0,
    &--none {
      border-top-width: $cbds-tkn-borderWidth__xs;
    }
  }

  // Set spacing for inlineLeft items
  .cbdsws-c-tokensTable__spaceInlineSpecBlock--left-halfX    { padding: $cbds-tkn-space__inlineLeft--halfX; }
  .cbdsws-c-tokensTable__spaceInlineSpecBlock--left-1x       { padding: $cbds-tkn-space__inlineLeft--1x; }
  .cbdsws-c-tokensTable__spaceInlineSpecBlock--left-2x       { padding: $cbds-tkn-space__inlineLeft--2x; }
  .cbdsws-c-tokensTable__spaceInlineSpecBlock--left-3x       { padding: $cbds-tkn-space__inlineLeft--3x; }
  .cbdsws-c-tokensTable__spaceInlineSpecBlock--left-4x       { padding: $cbds-tkn-space__inlineLeft--4x; }
  .cbdsws-c-tokensTable__spaceInlineSpecBlock--left-6x       { padding: $cbds-tkn-space__inlineLeft--6x; }
  .cbdsws-c-tokensTable__spaceInlineSpecBlock--left-8x       { padding: $cbds-tkn-space__inlineLeft--8x; }

  // Set spacing for inlineRight items
  .cbdsws-c-tokensTable__spaceInlineSpecBlock--right-halfX   { padding: $cbds-tkn-space__inlineRight--halfX; }
  .cbdsws-c-tokensTable__spaceInlineSpecBlock--right-1x      { padding: $cbds-tkn-space__inlineRight--1x; }
  .cbdsws-c-tokensTable__spaceInlineSpecBlock--right-2x      { padding: $cbds-tkn-space__inlineRight--2x; }
  .cbdsws-c-tokensTable__spaceInlineSpecBlock--right-3x      { padding: $cbds-tkn-space__inlineRight--3x; }
  .cbdsws-c-tokensTable__spaceInlineSpecBlock--right-4x      { padding: $cbds-tkn-space__inlineRight--4x; }
  .cbdsws-c-tokensTable__spaceInlineSpecBlock--right-6x      { padding: $cbds-tkn-space__inlineRight--6x; }
  .cbdsws-c-tokensTable__spaceInlineSpecBlock--right-8x      { padding: $cbds-tkn-space__inlineRight--8x; }


  //
  // Inset
  // ==========================================================================

  .cbdsws-c-tokensTable__spaceInsetSpecBlock {
    display: flex;
    padding: $cbds-tkn-space__6x;
    color: #0091ea;
    background: lighten(#80d8ff, 10%);
    border: $cbds-tkn-borderWidth__xs solid lighten(#40c4ff, 10%);

    &--0,
    &--none {
      padding: 0;
      border: none;
    }
  }

  .cbdsws-c-tokensTable__spaceInsetElement {
    width: 100%;
    height: 48px;
    background-color: $cbds-tkn-backgroundColor__white;
    border-width: $cbds-tkn-borderWidth__xs;
    border-style: dashed;
    border-color: $cbds-tkn-borderColor__neutral--light;
    // Put outside blue border on Element and up elevation to see the border
    outline: $cbds-tkn-borderWidth__xs solid lighten(#40c4ff, 10%);
    z-index: 2;
  }

  .cbdsws-c-tokensTable__spaceInsetSpecBlock--halfX    { padding: $cbds-tkn-space__inset--halfX; }
  .cbdsws-c-tokensTable__spaceInsetSpecBlock--1x       { padding: $cbds-tkn-space__inset--1x; }
  .cbdsws-c-tokensTable__spaceInsetSpecBlock--2x       { padding: $cbds-tkn-space__inset--2x; }
  .cbdsws-c-tokensTable__spaceInsetSpecBlock--3x       { padding: $cbds-tkn-space__inset--3x; }
  .cbdsws-c-tokensTable__spaceInsetSpecBlock--4x       { padding: $cbds-tkn-space__inset--4x; }
  .cbdsws-c-tokensTable__spaceInsetSpecBlock--6x       { padding: $cbds-tkn-space__inset--6x; }
  .cbdsws-c-tokensTable__spaceInsetSpecBlock--8x       { padding: $cbds-tkn-space__inset--8x; }
  .cbdsws-c-tokensTable__spaceInsetSpecBlock--12x      { padding: $cbds-tkn-space__inset--12x; }
  .cbdsws-c-tokensTable__spaceInsetSpecBlock--16x      { padding: $cbds-tkn-space__inset--16x; }


  //
  // Stack
  // ==========================================================================

  .cbdsws-c-tokensTable__spaceStackSpecBlock {
    display: flex;
    color: #6200ea;
    background: lighten(#b388ff, 10%);
    border: $cbds-tkn-borderWidth__xs solid lighten(#7c4dff, 10%);

    &--0,
    &--none {
      border: none;
    }
  }

  .cbdsws-c-tokensTable__spaceStackElement {
    height: 48px;
    background-color: $cbds-tkn-color__white;
    border-width: $cbds-tkn-borderWidth__xs;
    border-style: dashed;
    border-color: $cbds-tkn-borderColor__neutral--light;

    // &--0,
    // &--none {
    //   border-top-width: $cbds-tkn-borderWidth__xs;
    // }
  }

  .cbdsws-c-tokensTable__spaceStackSpecBlock--halfX    { padding: $cbds-tkn-space__stack--halfX; }
  .cbdsws-c-tokensTable__spaceStackSpecBlock--1x       { padding: $cbds-tkn-space__stack--1x; }
  .cbdsws-c-tokensTable__spaceStackSpecBlock--2x       { padding: $cbds-tkn-space__stack--2x; }
  .cbdsws-c-tokensTable__spaceStackSpecBlock--3x       { padding: $cbds-tkn-space__stack--3x; }
  .cbdsws-c-tokensTable__spaceStackSpecBlock--4x       { padding: $cbds-tkn-space__stack--4x; }
  .cbdsws-c-tokensTable__spaceStackSpecBlock--6x       { padding: $cbds-tkn-space__stack--6x; }
  .cbdsws-c-tokensTable__spaceStackSpecBlock--8x       { padding: $cbds-tkn-space__stack--8x; }
  .cbdsws-c-tokensTable__spaceStackSpecBlock--12x      { padding: $cbds-tkn-space__stack--12x; }
  .cbdsws-c-tokensTable__spaceStackSpecBlock--16x      { padding: $cbds-tkn-space__stack--16x; }
  .cbdsws-c-tokensTable__spaceStackSpecBlock--24x      { padding: $cbds-tkn-space__stack--24x; }
  .cbdsws-c-tokensTable__spaceStackSpecBlock--32x      { padding: $cbds-tkn-space__stack--32x; }






  // ==========================================================================
  // Typography
  // ==========================================================================


  //
  // Font Family
  // ==========================================================================

  .cbdsws-c-tokensTable__fontFamilyExample--cell {
    width: 256px;
  }

  .cbdsws-c-tokensTable__fontFamilyExample--text {
    font-size: $cbds-tkn-fontSize__40;
    color: $cbds-tkn-textColor__primary;
  }


  //
  // Font Role & Type
  // ==========================================================================

  .cbdsws-c-tokensTable__fontRoleExample,
  .cbdsws-c-tokensTable__fontTypeExample {
    width: 256px;

    code {
      margin-top: $cbds-tkn-space__1x;
      color: $cbds-tkn-colorBrand__black;
    }

    pre {
      margin-top: 0;
    }
  }

  .cbdsws-c-tokensTable__fontTypeExample--text {
    white-space: nowrap;
    color: $cbds-tkn-colorBrand__black;
    font-size: $cbds-tkn-fontSize__40;
  }

  .cbdsws-c-tokensTable__fontRoleExample--heading,
  .cbdsws-c-tokensTable__fontRoleExample--heading--handwritten {
    h1 {
      color: $cbds-tkn-colorBrand__black;
      font-size: $cbds-tkn-fontSize__100;
      font-weight: $cbds-tkn-fontWeight__semiBold;
    }
  }


  //
  // Font Size
  // ==========================================================================

  .cbdsws-c-tokensTable__fontSizeExample--text,
  .cbdsws-c-tokensTable__typeScaleExample--text {
    max-width: 420px;
    color: $cbds-tkn-textColor__primary;
    white-space: nowrap;
    overflow: hidden;
  }


  //
  // Font Style, Font Weight, Line Height and Letter Spacing
  // ==========================================================================

  .cbdsws-c-tokensTable__fontStyleExample--cell,
  .cbdsws-c-tokensTable__fontWeightExample--cell,
  .cbdsws-c-tokensTable__lineHeightExample--cell,
  .cbdsws-c-tokensTable__letterSpacingExample--cell {
    width: 256px;
  }

  .cbdsws-c-tokensTable__fontStyleExample--text,
  .cbdsws-c-tokensTable__fontWeightExample--text,
  .cbdsws-c-tokensTable__lineHeightExample--text,
  .cbdsws-c-tokensTable__letterSpacingExample--text {
    white-space: nowrap;
    color: $cbds-tkn-colorBrand__black;
    font-size: $cbds-tkn-fontSize__40;
  }
