// ==========================================================================
// --------------------------------------------------------------------------
// #DOC -> Components / Icon Page
// --------------------------------------------------------------------------
// ==========================================================================


.cbdsws-c-codeDuo {
  .cbds-c-navbar__closeIcon {
    fill: #dac500;
  }

  .olb-c-modal__closeIcon {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 48px;
    fill: #3c3c3c;
    vertical-align: middle;
  }

  // Example in Icons / Usage Examples
  .olb-c-alert__closeIcon {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    fill: $cbds-tkn-color__black;
    vertical-align: middle;
  }
}


.cbdsws-c-codeDuo__previewExample .cbds-c-icon {
  width: 48px;
  height: 48px;
}
