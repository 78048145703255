// ==========================================================================
// --------------------------------------------------------------------------
// #TOKENS TABLE ROW
// --------------------------------------------------------------------------
// ==========================================================================


// Allow Token Name to break
.cbdsws-c-tokensTable__name {
    .cbdsws-c-inlineCodeBtn__code {
      word-break: break-word;
    }
  }


  .cbdsws-c-tokensTable__descRelease {
    display: block;
    margin-top: $cbds-tkn-space__2x;
    font-size: $cbds-tkn-fontSize__10;
    line-height: 12px;
    color: $cbds-tkn-color__neutral--700;
  }

  .cbdsws-c-tokensTable__description {
    line-height: 1.5;
  }

  .cbdsws-c-tokensTable__descMetaName {
    margin: 0;
    font-size: $cbds-tkn-fontSize__40;
    line-height: 24px;
    font-weight: $cbds-tkn-fontWeight__semiBold;
    color: $cbds-tkn-color__black;

    @media screen and (max-width: $cbds-tkn-breakpoint__md) {
      font-size: $cbds-tkn-fontSize__30;
    }
  }

  .cbdsws-c-tokensTable__descText {
    margin-top: $cbds-tkn-space__1x;
  }

  .cbdsws-c-tokensTable__status {
    text-align: center;
  }


  // Set Value to not break
  .cbdsws-c-tokensTable__value {
    .cbdsws-c-inlineCodeBtn__code {
      white-space: nowrap;
    }
  }


  // But allow Value to break for certain Token Types
  .cbdsws-c-tokensRow__body,
  .cbdsws-c-tokensRow__fontFamily,
  .cbdsws-c-tokensRow__fontRole,
  .cbdsws-c-tokensRow__fontType,
  .cbdsws-c-tokensRow__headings {
    .cbdsws-c-tokensTable__value {
      .cbdsws-c-inlineCodeBtn__code {
        white-space: normal;
      }
    }
  }


  .cbdsws-c-tokensTable__aliasValue {
    margin-top: $cbds-tkn-space__1x;

    .cbdsws-c-inlineCodeBtn {
      padding: 0;
      font-size: $cbds-tkn-typeScale__200;
      color: $cbds-tkn-color__neutral--700;
      border: 0;
      background: none;
      pointer-events: none;
    }
  }
