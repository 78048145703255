.cbdsws-l-sidebar {
  padding: 32px;
  box-sizing: border-box;
  position: sticky;
  top: 0;

  &__buttons {
    display: none;

    @media(max-width: $cbds-tkn-breakpoint__md) {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }

    a {
      display: flex;
      align-items: center;
      color: black;
      text-decoration: none;
      justify-content: space-between;
    }
  }

  &__closeMenuButton {
    display: none;
    justify-content: flex-end;
    margin: -16px -16px 16px;
  }

  &__homeButton {
    display: inline-flex;
    align-items: center;
  }

  @media(max-width: $cbds-tkn-breakpoint__md) {
    &__closeMenuButton {
      display: flex;
    }
    .cbdsws-c-logo {
      display: none;
    }
  }
}
