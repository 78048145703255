// ==========================================================================
// --------------------------------------------------------------------------
// #ADDRESS
// --------------------------------------------------------------------------
// ==========================================================================


[dir="ltr"] .cbdsws-l-contentContainer,
.cbdsws-l-contentContainer {
  address {
    margin: $cbds-tkn-space__stack--4x;
    font-style: $cbds-tkn-fontStyle__normal;
  }
}
