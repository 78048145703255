// ==========================================================================
// --------------------------------------------------------------------------
// #SCROLLBAR
// --------------------------------------------------------------------------
// ==========================================================================


//
// Override Fract Theme customization of browser default scrollbars. Tried to
// get it back to Chrome's default style as best I could.
// Using the all, unsert, initial, or revert keywords on properties didn't seem
// to work.
//
// https://developer.mozilla.org/en-US/docs/Web/CSS/initial
// https://developer.mozilla.org/en-US/docs/Web/CSS/all
//

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  @media screen and (max-width: $cbds-tkn-breakpoint__sm) {
    width: 16px;
  }
}


::-webkit-scrollbar-button {
  height: unset;
  width: unset;
}

::-webkit-scrollbar-thumb {
  background: rgb(194, 194, 194);
  border: 0;
  border-radius: $cbds-tkn-borderRadius__lg;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(125, 125, 125);
}

::-webkit-scrollbar-thumb:active {
  background: rgb(125, 125, 125);
}

::-webkit-scrollbar-track {
  background: rgb(251, 251, 251);
  border-left: $cbds-tkn-borderWidth__xs solid rgb(232, 232, 232);
  border-radius: 0;
}

::-webkit-scrollbar-track:hover {
  background: rgb(251, 251, 251);
}

::-webkit-scrollbar-track:active {
  background: rgb(251, 251, 251);
}

::-webkit-scrollbar-corner {
  background: rgb(251, 251, 251);
}
